<style>
.PlanejamentoAcaoFormCampos__Card-rateio {
  min-height: 400px;
  height: 100%;
}
</style>
<template>
<div>
  <v-card class="PlanejamentoAcaoFormCampos__Card-rateio">
    <v-card-title>
      <div class="card-title">
        {{ $tc('title.rateio_entre_naturezas_operacao', 1) }}
      </div>
    </v-card-title>
    <v-card-text class="pb-0">
      <v-container fluid grid-list-md class="pb-0">
        <v-row dense>
          <v-col cols="12" md="4" sm="6">
            <span>{{ $tc('label.valor_total') }}: {{ getMoney(valorTotalAcao) }}</span>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <span>{{ $tc('label.valor_pendente') }}: {{ getMoney(valorPendente) }}</span>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <span>{{ $tc('label.valor_alocado') }}: {{ getMoney(valorAlocado) }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text class="py-0">
      <v-container fluid grid-list-md class="py-0">
        <v-form ref="form" v-show="acaoEmCadastro">
        <v-row dense>
          <v-col cols="3">
            <v-autocomplete
            id="tipo_uso"
            class="custom-autocomplete"
            v-model="tipoUso"
            :rules="[rules.required]"
            :items="listaTiposUso"
            :label="`${$tc('label.tipo_uso', 1)} *`"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            item-text="nome"
            item-value="id"
            required
            :disabled="!acaoEmCadastro || somenteLeitura || valorTotalAcaoZerado"
            :clearable="true"
            @click.native="(i) => buscaAutocomplete(i, buscarTiposUso)"
          ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-combobox
              id="budget_id"
              class="custom-combobox"
              v-model="budgetId"
              :rules="[rules.required]"
              :items="listaBudgetId"
              :label="`${$tc('label.budget_id', 1)} *`"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              item-text="codBudgetId"
              item-value="id"
              required
              :disabled="!acaoEmCadastro || somenteLeitura || valorTotalAcaoZerado"
              :clearable="true"
              @input="(i) => buscaAutocomplete(i, buscarBudgetIds)"
            ></v-combobox>
          </v-col>
          <v-col cols="3">
          <input-money
            id="valor"
            v-model="valor"
            :label="`${$tc('label.valor', 1)} *`"
            class="v-text-field"
            :max="9999999999"
            :rules="[rules.required, v => rules.valorMinimo(v, 0.000000001), rules.valorMaiorQueValorPendente]"
            :disabled="somenteLeitura || valorTotalAcaoZerado"
            :persistent-hint="true"/>
          </v-col>
          <v-col cols="3" align-self="center" style="text-align: center">
            <v-btn
              color="primary"
              @click="adicionarLinhaRateio"
              :disabled="!acaoEmCadastro || somenteLeitura || valorTotalAcaoZerado">
              {{ $t('label.adicionar') }}
            </v-btn>

          </v-col>
        </v-row>
      </v-form>
      </v-container>
    </v-card-text>
    <v-row v-show="existeErroDuplicidadeLinha">
      <v-col cols="12" align-self="center" style="text-align: center">
        <v-alert type="warning"
          elevation="2"
          class="pa-2"
        >
          {{ $tc('message.linha_rateio_duplicada', 1) }}
        </v-alert>
      </v-col>
    </v-row>
    <v-card-text class="p-0">
      <v-data-table
        id="tabela_linha_rateio"
        :headers="headersLinhaRateio"
        :items="listaRateio"
        :no-data-text="$t('label.tabela_sem_conteudo')"
        :footer-props="{ itemsPerPageOptions: [5, 10] }"
        :items-per-page="5"
        dense
      >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-if="items.length === 0">
            <td :colspan="headersLinhaRateio.length" class="text-center">
              {{ $t('label.tabela_sem_conteudo') }}
            </td>
          </tr>
          <tr v-else v-for="(item, index) in items" :key="index" :style="item.backgroundColor">
            <td>
              <v-btn icon @click="openConfirmRemocaoLinhaRateio(item)" v-show="acaoEmCadastro">
                <v-icon>delete</v-icon>
              </v-btn>
            </td>
            <td>{{ item.tipoUso.nome }}</td>
            <td>{{ item.budgetId.codBudgetId }}</td>
            <td>{{ getMoney(item.valor) }}</td>
          </tr>
        </tbody>
      </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <confirm
    ref="confirmRemocaolinhaRateio"
    :message="$t('message.deseja_remover_linha_rateio')"
    :persistent="true"
    @agree="removerLinhaRateio">
  </confirm>
</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { buscaAutocomplete, triggerSelecao } from '@/common/functions/autocomplete-utils';
import { getMoney } from '@/common/functions/helpers';
import InputMoney from '@/shared-components/inputs/InputMoney';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  components: {
    InputMoney,
    Confirm,
  },
  props: {
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
    valorTotalAcaoRateio: Number,
    limpaListaRateio: Boolean,
    novaAcao: Boolean,
  },
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      listaTiposUso: [],
      tipoUso: null,
      listaBudgetId: [],
      budgetId: null,
      valor: 0,
      valorTotalAcao: 0,
      valorPendente: 0,
      valorAlocado: 0,
      countListaRateio: 0,
      listaRateio: [],
      listaRateioMontada: [],
      existeErroDuplicidadeLinha: false,
      linhaRateioSelecionada: [],
      listaBudgetIdExclusao: [],
      listaNovosBudgetId: [],
      novoBudgetId: '',
      headersLinhaRateio: [
        {
          text: '', value: 'actions', width: '1%', sortable: false,
        },
        {
          text: this.$tc('label.tipo_uso', 1), value: 'tipoUsoNome', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.budget_id', 1), value: 'budgetIdNome', sortable: false, width: '10%',
        },
        {
          text: this.$tc('label.valor', 1), value: 'valor', sortable: false, width: '10%',
        },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        valorMinimo: (value, min) => value >= min || this.$t('errors.valor.maior_zero_inteiro', { min }),
        valorMaiorQueValorPendente: (value) => value <= parseFloat(this.valorPendente.toFixed(2)) || this.$t('errors.valor_rateio_excedendo_valor_total_campanha'),
        valorNegativo: (value) => value >= 0 || this.$t('message.valor_negativo'),
      },
    };
  },
  watch: {
    valorTotalAcaoRateio(valorTotalAcao) {
      this.valorTotalAcao = valorTotalAcao;
      const valorPendente = parseFloat(this.valorTotalAcao).toFixed(2) - parseFloat(this.valorAlocado).toFixed(2);
      this.valorPendente = parseFloat(valorPendente.toFixed(2));
      this.limparCampos();
      if (this.valorTotalAcaoZerado) {
        this.limparCampos();
      }
      this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__isValorPendenteZerado', this.isValorPendenteZerado());
    },
    listaRateio: {
      handler(lista) {
        this.countListaRateio = lista.length;
        this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__countListaRateioTipoUso', this.countListaRateio);
      },
      deep: true,
    },
    limpaListaRateio(indLimparListaRateio) {
      if (indLimparListaRateio) {
        this.limparCampos();
        this.listaRateio = [];
        this.listaRateioMontada = [];
        this.linhaRateioSelecionada = [];
        this.valorPendente = 0;
        this.valorAlocado = 0;
      }
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    acaoEmCadastro() {
      return this.acao.status === 'EM_CADASTRO';
    },
    valorTotalAcaoZerado() {
      return this.valorTotalAcao === 0;
    },
  },
  methods: {
    getMoney,
    buscaAutocomplete,
    triggerSelecao,
    buscarTiposUso() {
      return this.workflowAcaoMagaluResource
        .buscarTiposUso()
        .then((res) => {
          this.listaTiposUso = res.body;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarBudgetIds() {
      return this.planejamentoAcaoResource
        .buscarBudgetIds()
        .then((res) => {
          this.listaBudgetId = res.body;
          if (this.listaBudgetIdExclusao.length) {
            this.listaBudgetIdExclusao.forEach((item) => {
              this.listaBudgetId.push(item);
            });
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    adicionarLinhaRateio() {
      if (this.$refs.form.validate()) {
        if (this.tipoUso && this.budgetId && this.valor && this.valor <= parseFloat(this.valorPendente.toFixed(2)) && this.$refs.form.validate()) {
          const tipoUso = this.listaTiposUso.find((item) => item.id === this.tipoUso);
          let budgetId = {};
          if (typeof this.budgetId === 'string') {
            const budgetIdFormatado = this.budgetId.replace(/\s+/g, '').toLowerCase();
            const budgetExistente = this.listaBudgetId.find((item) => item.codBudgetId.replace(/\s+/g, '').toLowerCase() === budgetIdFormatado);

            if (!budgetExistente) {
              const novoId = this.listaBudgetId.length + 1000;
              budgetId = { id: novoId, codBudgetId: this.budgetId };
              this.listaBudgetId.push(budgetId);
              this.listaNovosBudgetId.push(budgetId.codBudgetId);
              this.listaBudgetIdExclusao.push(budgetId);
            } else {
              budgetId = budgetExistente;
            }
          } else {
            budgetId = this.listaBudgetId.find((item) => item.id === this.budgetId.id);
          }
          let linhaDuplicada = false;

          this.listaRateio.forEach((item) => {
            const budgetIdExistenteFormatado = item.budgetId.codBudgetId.replace(/\s+/g, '').toLowerCase();
            const budgetIdNovoFormatado = budgetId.codBudgetId.replace(/\s+/g, '').toLowerCase();

            if (item.tipoUso.nome === tipoUso.nome && budgetIdExistenteFormatado === budgetIdNovoFormatado) {
              linhaDuplicada = true;
              item.backgroundColor = 'background-color:#ef5350;';
            } else {
              item.backgroundColor = '';
            }
          });

          if (!linhaDuplicada) {
            this.listaRateio.push({
              tipoUso,
              budgetId,
              valor: this.valor,
            });
            this.limparCampos();
            this.existeErroDuplicidadeLinha = false;
            this.atualizarValores();
          } else {
            this.existeErroDuplicidadeLinha = true;
          }
        }
      } else {
        this.$toast(this.$t('message.campo_obrigatorio'));
      }
    },
    exibeErroDuplicidadeLinha(linhaRateio) {
      linhaRateio.backgroundColor = 'background-color:#ef5350;';
    },
    someErroDuplicidadeLinha(linhaRateio) {
      this.existeErroDuplicidadeLinha = false;
      linhaRateio.backgroundColor = '';
    },
    limparLinhasDuplicadas(linhaRateio) {
      linhaRateio.forEach((item) => {
        item.backgroundColor = '';
      });
    },
    openConfirmRemocaoLinhaRateio(linhaRateio) {
      this.linhaRateioSelecionada = linhaRateio;
      this.$refs.confirmRemocaolinhaRateio.open();
    },
    removerLinhaRateio() {
      this.listaRateio.splice(this.listaRateio.indexOf(this.linhaRateioSelecionada), 1);

      const codBudgetIdSelecionado = this.linhaRateioSelecionada.budgetId.codBudgetId;
      const existeNaExclusao = this.listaBudgetIdExclusao.some((item) => item.codBudgetId === codBudgetIdSelecionado);

      const count = this.listaRateio.filter((item) => item.budgetId.codBudgetId === codBudgetIdSelecionado).length;

      if (existeNaExclusao && count === 0) {
        this.listaBudgetId = this.listaBudgetId.filter((item) => item.codBudgetId !== codBudgetIdSelecionado);
      }
      this.atualizarValores();
    },
    atualizarValores() {
      this.valorAlocado = this.listaRateio.reduce((acc, linha) => acc + parseFloat(linha.valor), 0);
      this.valorPendente = parseFloat(this.valorTotalAcao).toFixed(2) - parseFloat(this.valorAlocado).toFixed(2);
      this.linhaRateioSelecionada = [];
      this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__isValorPendenteZerado', this.isValorPendenteZerado());
    },
    limparCampos() {
      this.tipoUso = null;
      this.budgetId = null;
      this.valor = 0;
      this.$refs.form.resetValidation();
      this.$refs.form.validate();
    },
    getObjetoFormatado() {
      this.listaRateioMontada = [];
      this.listaRateio.forEach((item) => {
        this.listaRateioMontada.push({
          idAcao: this.acao.id,
          idTipoUso: item.tipoUso.id,
          idBudget: item.budgetId.id,
          codBudgetId: item.budgetId.codBudgetId,
          valor: item.valor,
        });
      });
      return {
        listaRateioMontada: this.listaRateioMontada,
        valorTotal: this.valorTotalAcaoRateio,
        valorPendente: this.valorPendente,
        valorAlocado: this.valorAlocado,
      };
    },
    salvarNovoBudgetId() {
    /* eslint-disable */
      const promises = this.listaNovosBudgetId.map((codBudget) => this.planejamentoAcaoResource.salvarNovoBudgetId({ codBudgetId: codBudget }, { codBudgetId: codBudget }));
    /* eslint-enable */
      return Promise.all(promises)
        .then(() => this.buscarBudgetIds().then(() => {
          this.listaRateio.forEach((rateio) => {
            const budget = this.listaBudgetId.find((itemBudget) => itemBudget.codBudgetId === rateio.budgetId.codBudgetId);
            if (budget) {
              rateio.budgetId.id = budget.id;
            }
          });
          this.listaNovosBudgetId = [];
        })
          .catch((err) => {
            this.$toast(err.data.error);
          }));
    },
    valida() {
      let secaoRateioValida = true;
      if (!this.listaRateio.length) {
        secaoRateioValida = false;
      }
      return secaoRateioValida;
    },
    carregaListaRateioContabil() {
      this.listaRateio = this.acao.linhasRateioContabil;
      if (this.listaRateio.length) {
        this.listaRateio.forEach((item) => {
          const tipoUso = this.listaTiposUso.find((itemTipoUso) => itemTipoUso.id === item.id_tipo_uso);
          const budgetId = this.listaBudgetId.find((itemBudgetId) => itemBudgetId.id === item.id_budget);
          item.tipoUso = {
            id: tipoUso.id,
            nome: tipoUso.nome,
          };
          item.budgetId = {
            id: budgetId.id,
            codBudgetId: budgetId.codBudgetId,
          };
        });
        this.atualizarValoresCarregamento();
      }
    },
    atualizarValoresCarregamento() {
      const valorTotalAlocado = this.listaRateio.reduce((accumulator, currentItem) => accumulator + currentItem.valor, 0);
      this.valorAlocado = valorTotalAlocado;
      this.valorPendente = parseFloat(this.valorTotalAcao).toFixed(2) - parseFloat(this.valorAlocado).toFixed(2);
      this.$emit('PlanejamentoAcaoVerbaVariavelFormCampos__isValorPendenteZerado', this.isValorPendenteZerado());
    },
    isValorPendenteZerado() {
      return this.listaRateio.length > 0 && this.valorPendente === 0
        && parseFloat(this.valorAlocado).toFixed(2) === parseFloat(this.valorTotalAcao).toFixed(2);
    },
  },
  mounted() {
    Promise.all([
      this.buscarTiposUso(),
      this.buscarBudgetIds(),
    ]).then(() => {
      if (!this.novaAcao) {
        this.carregaListaRateioContabil();
      }
    });
  },
};
</script>
