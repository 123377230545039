<template>
  <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
    <v-form
      ref="formCamposAds"
      lazy-validation
      autocomplete="off">
      <v-card-title>
        <div class="card-title">
          {{ $t('title.campos_ads') }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                id="cod_campanha_ads"
                v-if="!indExibeListaCampanhas"
                :label="`${$tc('label.codigo_campanha', 1)} *`"
                v-model="formulario.codigoCampanhaAds"
                :rules="[rules.required]"
                :disabled="somenteLeitura"/>
              <v-autocomplete
                id="cod_lista_campanha_ads"
                v-else
                class="custom-autocomplete pt-0"
                v-model="formulario.campanhasFornecedor"
                return-object
                :label="`${$tc('label.codigo_campanha', 1)} *`"
                :items="campanhasFornecedor"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="descricao"
                item-value="idCampanhaAds"
                :rules="[rules.requiredMultiple]"
                required
                :disabled="somenteLeitura"
                :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
                chips
                deletable-chips
                clearable
                multiple
                @click:append="() => triggerSelecao('planejamento-autocomplete-campanhas-fornecedor')"
                @click.native="(i) => buscaAutocomplete(i, buscaCampanhasFornecedor)"
                :search-input.sync="triggerCampanhaFornecedor">
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <input-money
                id="valor_fee_ads"
                :label="`${$tc('label.valor_ads', 1)} *`"
                v-model="formulario.valorFeeAds"
                class="v-text-field"
                :max="9999999999"
                :min="0.01"
                :rules="[rules.required]"
                :disabled="somenteLeitura"/>
            </v-col>
            <v-col cols="12">
              <v-text-field
                id="cod_ads_trade"
                :label="`${$tc('label.codigo_ads_trade', 1)}`"
                v-model="formulario.codigoCampanhaTrade"
                type="Number"
                min="0"
                :rules="[rules.valorMaiorZero]"
                :disabled="somenteLeitura"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import InputMoney from '@/shared-components/inputs/InputMoney';
import { buscaAutocomplete, triggerSelecao } from '@/common/functions/autocomplete-utils';

export default {
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  components: {
    InputMoney,
  },
  watch: {
    triggerCampanhaFornecedor(val) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      this.timeout = window.setTimeout(() => {
        if (this.indExibeListaCampanhas) {
          this.buscaCampanhasFornecedor(val);
        }
      }, 500);
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      formulario: {
        codigoCampanhaAds: null,
        valorFeeAds: 0.0,
        codigoCampanhaTrade: null,
        campanhasFornecedor: null,
      },
      campanhasFornecedor: [],
      triggerCampanhaFornecedor: null,

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredMultiple: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
        valorMaiorZero: (v) => ((!!v && v > 0) || v === null || v === undefined) || this.$t('errors.valor.maior_zero_inteiro'),
      },
    };
  },
  computed: {
    indExibeListaCampanhas() {
      const { configuracao } = this.configuracao;
      return configuracao.passo3.indIntegracaoCodigoCampanhaAds;
    },
  },
  methods: {
    triggerSelecao,
    buscaAutocomplete,
    getObjetoFormatado() {
      const objeto = { ...this.formulario };
      return objeto;
    },
    valida() {
      return this.$refs.formCamposAds.validate();
    },
    montaObjetoAcao() {
      this.formulario = {
        codigoCampanhaAds: this.acao.codigoCampanhaAds,
        valorFeeAds: this.acao.valorFeeAds,
        codigoCampanhaTrade: this.acao.codigoCampanhaTrade,
        campanhasFornecedor: this.acao.campanhasFornecedor,
      };
    },
    buscaCampanhasFornecedor() {
      const params = {
        status: 'EM_FATURAMENTO',
        cnpjFornecedor: this.acao.fornecedor.desCnpj,
        tipoBeneficio: 'NOTA_DEBITO',
        indAtivo: true,
      };

      this.planejamentoAcaoResource.buscaCampanhasAds(params)
        .then((res) => {
          this.campanhasFornecedor = res.body;
          this.campanhasFornecedor.map((c) => {
            c.idCampanhaAds = c.id;
            c.nomeCampanha = c.nome;
            c.descricao = `${c.id} - ${c.nome}`;
            return c;
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (!this.novaAcao) {
      this.montaObjetoAcao();
      if (this.indExibeListaCampanhas) {
        this.buscaCampanhasFornecedor();
      }
    }
  },
};
</script>
