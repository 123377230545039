<template>
  <div>
    <v-card-title>
      <div>
        <span>{{ $tc('label.anexo', 1)}}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" icon :disabled="!podeAnexar" v-on="on" @click="fazerUpload">
              <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <div class="painel-anexo">
      <v-row>
        <span v-if="novaAcao || isCopiaAcao" style="color: darkorange;" class="ma-3 ml-7">
          {{$t('message.upload_anexo_acao_indisponivel')}}
        </span>
        <list-files
          @ListFiles__arquivos="(files) => $emit('FormAnexoArquivo__qtde', files.length)"
          v-if="!novaAcao && !isCopiaAcao"
          :habilitar-exclusao="podeRemover"
          :habilitar-inativacao="habilitarInativacao"
          ref="listFiles"
          :uri="uri"></list-files>
      </v-row>
    </div>
    <upload-file-form
      v-if="podeAnexar"
      :id="novaAcao ? 500 : acao.id"
      :id-entidade="novaAcao ? 500 : acao.id"
      :name="`${novaAcao}` ? `uploadArquivo_0` : `uploadArquivo_${acao.id}`"
      ref="uploadArquivo"
      :title="$t('label.upload_anexo')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
    </upload-file-form>
    <confirm
      ref="modalConfirmacao"
      :message="$t('message.status_alterado')"
      @agree="confirmaRefresh"
      :agree-label="$t('label.ok')"
      :hideDisagree="true">
    </confirm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UploadFileForm from '@/shared-components/upload/UploadFileForm';
import Confirm from '@/shared-components/dialog/Confirm';
import basePath from '@/common/functions/api-resource';
import ListFiles from '@/shared-components/files/ListFiles';
import { skipLoading } from '@/common/functions/loading';

export default {
  name: 'PlanejamentoAcaoFormAnexoArquivo',
  components: {
    UploadFileForm,
    ListFiles,
    Confirm,
  },
  props: {
    acao: Object,
    novaAcao: Boolean,
    isCopiaAcao: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    habilitaAnexoFornecedor: {
      type: Boolean,
      default: false,
    },
    getOrigemAcao: Function,
  },
  data() {
    return {
      descricaoArquivo: null,
      exibeArquivos: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      planejamentoAcaoCadastroResource: this.$api.planejamentoAcaoCadastro(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.id}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.id}/upload`;
    },
    hasRole() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'ACAO_CRUD').length;
    },
    podeAnexar() {
      return !this.novaAcao && !this.isCopiaAcao && ((this.hasRole && this.acao.passoGeral
        && this.getStatusPermitidosUploadPlanejamento.indexOf(this.acao.passoGeral.status) > -1)
        || this.habilitaAnexoFornecedor);
    },
    getStatusPermitidosUploadPlanejamento() {
      const statusPermitidos = [];
      if (this.configuracao
        && this.configuracao.configuracao.passo1
        && this.configuracao.configuracao.passo1.statusPermitidosUploadPlanejamento) {
        const status = this.configuracao.configuracao.passo1.statusPermitidosUploadPlanejamento;
        if (status.emCadastro) {
          statusPermitidos.push('EM_CADASTRO');
        }
        if (status.aprovado) {
          statusPermitidos.push('APROVADO');
        }
        if (status.aguardandoAprovacao) {
          statusPermitidos.push('AGUARDANDO_APROVACAO');
        }
        if (status.reprovado) {
          statusPermitidos.push('REPROVADO');
        }
        if (status.emAnalise) {
          statusPermitidos.push('EM_ANALISE');
        }
        if (status.cancelado) {
          statusPermitidos.push('CANCELADO');
        }
      }
      return statusPermitidos;
    },
    possuiAcessoInativarAnexos() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'INATIVAR_E_VISUALIZAR_ANEXOS_INATIVOS').length;
    },
    podeRemover() {
      return (this.hasRole && this.acao.passoGeral
        && ['EM_CADASTRO', 'EM_ANALISE'].includes(this.acao.passoGeral.status))
        || this.habilitaAnexoFornecedor;
    },
    habilitarInativacao() {
      if (!this.acao.passoGeral || !this.acao.passoGeral.status) {
        return false;
      }
      return this.possuiAcessoInativarAnexos
        && ['AGUARDANDO_APROVACAO', 'APROVADO'].includes(this.acao.passoGeral.status);
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      const indexUltimoPonto = this.descricaoArquivo.lastIndexOf('.');
      const nomeArquivo = this.descricaoArquivo.substring(0, indexUltimoPonto);
      const extensaoArquivo = this.descricaoArquivo.substring(indexUltimoPonto, this.descricaoArquivo.length);
      const { fornecedor, grupoFornecedor } = this.getOrigemAcao();
      if (grupoFornecedor) {
        this.descricaoArquivo = nomeArquivo.concat('-').concat(grupoFornecedor.nomExtensao);
      } else {
        this.descricaoArquivo = nomeArquivo.concat('-').concat(fornecedor.nome);
      }
      this.descricaoArquivo = this.descricaoArquivo.concat('-').concat(this.acao.id).concat(extensaoArquivo);
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
    fazerUpload() {
      skipLoading();
      this.planejamentoAcaoCadastroResource.buscarStatus({ idAcao: this.acao.id })
        .then((res) => {
          if (res.bodyText && this.acao.passoGeral.status) {
            this.$refs.uploadArquivo.open();
          } else {
            this.$refs.modalConfirmacao.open();
          }
        }).catch((err) => {
          this.$toast(err.data.error);
        });
    },
    confirmaRefresh() {
      this.$refs.PlanejamentoEdicaoAcaoVerbaVariavelForm.refresh();
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
  padding: 12px 0 0 12px;
  font-size: 16px !important;
  color: red;
}
.painel-anexo .container.mx-0 {
  max-width: 97% !important;
}
</style>
