import { render, staticRenderFns } from "./PlanejamentoAcaoVerbaVariavelModalSelecaoProduto.vue?vue&type=template&id=2152d50b"
import script from "./PlanejamentoAcaoVerbaVariavelModalSelecaoProduto.vue?vue&type=script&lang=js"
export * from "./PlanejamentoAcaoVerbaVariavelModalSelecaoProduto.vue?vue&type=script&lang=js"
import style0 from "./PlanejamentoAcaoVerbaVariavelModalSelecaoProduto.vue?vue&type=style&index=0&id=2152d50b&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports