<template>
  <div>
    <v-dialog v-model="exibeModalProduto" max-width="1200px" persistent>
      <v-card>
        <v-card-title>
          <div class="card-title">
            {{ $tc('label.selecionar_produto', 2) }}
          </div>
        </v-card-title>
        <v-card-text>
          <v-row v-if="exibeModalProduto">
            <v-col cols="4">
              <v-text-field
                :label="$tc('label.categoria', 1)"
                v-model="codNomeCategoria"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                :label="$tc('label.familia', 1)"
                v-model="codNomeFamilia"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="d-flex" v-if="!somenteLeitura && !isEditarLinhaProduto">
              <v-switch
                v-model="flagLinhaSku"
                :label="$tc('label.adicionar_linha_por_sku', 1)"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="12" sm="12" class="mx-3 pt-0 pb-0">
              <v-text-field
                v-model.trim="searchQuery"
                append-icon="search"
                :label="$t('label.pesquisar_modal_selecao_produto')"
                single-line
                clearable
                hide-details>
              </v-text-field>
            </v-col>
            <h5  class="ml-6 pb-4 orange--text">{{ $tc('message.info_selecao_produtos', 1) }}</h5>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="listaProdutos"
            v-model="selected"
            :key="tableModalProdutosKey"
            :show-select="!somenteLeitura && !isVisualizarLinhaProduto"
            @toggle-select-all="realizarSelecaoTotal"
            :item-class="isDuplicado"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50],
            }">
            <template v-slot:item.kit="{ item }">
              <v-tooltip bottom v-if="item.indKit">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on"  @click="visualizarProdutosKit(item)">
                    <v-icon>visibility</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('label.kit') }}</span>
              </v-tooltip>
                <span v-else >{{ '-' }}</span>
          </template>
          </v-data-table>
          <v-row v-show="existeErroDuplicidadeLinha">
            <v-col cols="12" align-self="center" style="text-align: center">
              <v-alert type="warning"
                elevation="2"
                class="pa-2"
              >
                {{ $tc('message.linha_produto_duplicada', 1) }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secundary"
            @click="close">{{ (somenteLeitura || isVisualizarLinhaProduto) ? $t('label.voltar') : $t('label.cancelar') }}</v-btn>
          <v-btn
            color="primary"
            class="mr-3"
            v-if="!somenteLeitura && !isVisualizarLinhaProduto && selected.length"
            @click="selecionarProdutos" >{{ $t('label.selecionar') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal-produtos-kit
    ref="modalProdutosKit">
    </modal-produtos-kit>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { copyObject, removerItensDuplicadosPorId } from '@/common/functions/helpers';
import { buscarProdutosLinhaProduto } from '@/common/resources/planejamento-acao-cadastro';
import ModalProdutosKit from './PlanejamentoAcaoVerbaVariavelProdutosKit';

export default {
  name: 'PlanejamentoAcaoVerbaVariavelModalSelecaoProduto',
  components: {
    ModalProdutosKit,
  },
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
    categoriaSelecionada: Object,
    familiaSelecionada: Object,
    isTodosSelecionado: Boolean,
    getOrigemAcao: Function,
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    codNomeCategoria() {
      if (this.categoriaSelecionada && this.categoriaSelecionada.codNomeCategoria) {
        return this.categoriaSelecionada.codNomeCategoria;
      }
      return '';
    },
    codNomeFamilia() {
      if (this.familiaSelecionada && this.familiaSelecionada.codNomeFamilia) {
        return this.familiaSelecionada.codNomeFamilia;
      }
      return '';
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      exibeModalProduto: false,
      searchQuery: null,
      show: false,
      listaProdutos: [],
      listaProdutosFiltrada: [],
      linhaProdutoSelecionada: {},
      selected: [],
      tableModalProdutosKey: 0,
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      timeout: 0,
      esperar: false,
      iniciarTodosSelecionados: false,
      isVisualizarLinhaProduto: false,
      existeErroDuplicidadeLinha: false,
      isEditarLinhaProduto: false,
      tamanhoTotalProdutos: 0,
      indexLinhaProdutoSelecionada: -1,
      flagLinhaSku: false,
      headers: [
        { text: this.$tc('label.familia', 1), value: 'codNomeFamilia', sortable: false },
        { text: this.$tc('label.ibm', 1), value: 'desIbm', sortable: false },
        { text: this.$tc('label.cod_produto_com_digito', 1), value: 'codProdutoComDigito', sortable: false },
        { text: this.$tc('label.produto', 1), value: 'codNomeProduto', sortable: false },
        { text: this.$tc('label.kit', 1), value: 'kit', sortable: false },
        { text: this.$tc('label.referencia', 1), value: 'desModelo', sortable: false },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredLista: (value) => (!!value && value.length > 0) || this.$t('message.campo_obrigatorio'),
        valorPositivo: (value) => (!!value && value > 0) || this.$t('errors.valor.negativo'),
        valorMaiorQuePendente: (value) => (!!value && value <= this.valorOriginalPendente) || this.$t('errors.valor.maior_que_pendente'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  watch: {
    pagination: {
      handler() {
        if (!this.iniciarTodosSelecionados && this.exibeModalProduto) {
          this.filtrar();
        }
      },
      deep: true,
    },
    searchQuery() {
      if (this.exibeModalProduto) {
        this.pagination.page = 1;
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.filtrar();
        }, 500);
      }
    },
  },
  methods: {
    open(isTodosSelecionado = false) {
      if (isTodosSelecionado) {
        this.iniciarTodosSelecionados = true;
      } else {
        const buscaCompleta = true;
        const zeraPaginacao = false;
        this.iniciarTodosSelecionados = false;
        this.buscaProdutos(zeraPaginacao, buscaCompleta);
      }
      this.filtrar();
      this.existeErroDuplicidadeLinha = false;
      this.exibeModalProduto = true;
    },
    close() {
      this.exibeModalProduto = false;
      this.resetaModal();
    },
    isDuplicado(item) {
      const linhaDuplicada = 'linhaDuplicada';
      if (item.isDuplicado) {
        return linhaDuplicada;
      }
      return '';
    },
    visualizarLinhaProduto() {
      this.isVisualizarLinhaProduto = true;
      this.filtrar();
      this.exibeModalProduto = true;
    },
    editarLinhaProduto(index = null) {
      this.isEditarLinhaProduto = true;
      this.indexLinhaProdutoSelecionada = index;
      if (!this.linhaProdutoSelecionada.idsProdutos || !this.linhaProdutoSelecionada.idsProdutos.length) {
        this.iniciarTodosSelecionados = true;
      } else {
        this.selected = this.linhaProdutoSelecionada.listaProdutos;
        const buscaCompleta = true;
        const zeraPaginacao = false;
        this.iniciarTodosSelecionados = false;
        this.buscaProdutos(zeraPaginacao, buscaCompleta);
      }
      this.filtrar();
      this.exibeModalProduto = true;
    },
    filtrar() {
      if (this.esperar) return;
      this.esperar = true;
      setTimeout(() => {
        const isSelecaoInicial = true;
        if (this.iniciarTodosSelecionados) {
          this.realizarSelecaoTotal(null, isSelecaoInicial);
          this.iniciarTodosSelecionados = false;
        } else if (this.searchQuery && this.searchQuery.length) {
          this.realizarSelecaoTotal(null, !isSelecaoInicial);
        }
        this.buscaProdutos();
      }, 1000);
    },
    buscaProdutos(zeraPaginacao = false, buscaCompleta = false) {
      const { idFornecedor, idGrupoFornecedor, fornecedor } = this.getOrigemAcao();
      this.linhaProdutoSelecionada = {
        ...this.linhaProdutoSelecionada,
        idFornecedor,
        idGrupoFornecedor,
      };
      if (zeraPaginacao) {
        this.pagination.page = 1;
      }
      const params = {
        numeroPagina: this.pagination.page,
        tamanhoPagina: this.pagination.itemsPerPage,
        asc: this.pagination.sortDesc ? !this.pagination.sortDesc[0] : true,
        colunaOrdenacao: this.pagination.sortBy ? this.pagination.sortBy[0] : 'cod_nome_produto',
      };
      if (fornecedor) {
        params.id_grupo_fornecedor = fornecedor.idGrupoFornecedor;
      } else {
        params.id_grupo_fornecedor = idGrupoFornecedor;
      }
      if (this.searchQuery && this.searchQuery.length) {
        if (this.searchQuery.includes(',')) {
          const searchQueryArray = this.searchQuery.split(',');
          const auxArraySemEspaco = [];
          searchQueryArray.forEach((el) => auxArraySemEspaco.push(el.trim()));
          params.filtro_array = auxArraySemEspaco;
        } else {
          params.filtro = this.searchQuery;
        }
      }
      if (this.familiaSelecionada && this.familiaSelecionada.id !== 0) {
        params.id_familia = this.familiaSelecionada.id;
      }
      if (this.categoriaSelecionada && !params.id_familia) {
        params.id_categoria = this.categoriaSelecionada.id;
      }
      if (buscaCompleta) {
        params.numeroPagina = 1;
        params.tamanhoPagina = 999999;
      }
      if (this.isVisualizarLinhaProduto
        && this.linhaProdutoSelecionada.idsProdutos
        && this.linhaProdutoSelecionada.idsProdutos.length) {
        params.id_linha_produto = this.linhaProdutoSelecionada.id;
      }

      if (!this.isVisualizarLinhaProduto && this.linhaProdutoSelecionada.idsProdutos
        && this.linhaProdutoSelecionada.idsProdutos.length
        && !this.iniciarTodosSelecionados) {
        params.idsProdutosSelecionadosOrdenacao = this.linhaProdutoSelecionada.idsProdutos;
      }

      return buscarProdutosLinhaProduto(params, this.$resource)
        .then((res) => {
          if (buscaCompleta) {
            this.todosProdutos = res.data.resposta;
            if (this.tamanhoTotalProdutos === 0) {
              this.tamanhoTotalProdutos = this.todosProdutos.length;
            }
          } else {
            this.totalPage = res.data.quantidadeRegistrosPagina;
            this.listaProdutos = res.data.resposta;
            this.listaProdutos.forEach((produto) => {
              produto.isDuplicado = false;
            });
            this.tableModalProdutosKey += 1;
            this.pararEsperar();
          }
        });
    },
    realizarSelecaoTotal(e, isSelecaoInicial = false) {
      const buscaCompleta = true;
      const zeraPaginacao = false;
      this.buscaProdutos(zeraPaginacao, buscaCompleta)
        .then(() => {
          if (isSelecaoInicial) {
            this.selected = [...this.todosProdutos];
          } else if (this.searchQuery && this.searchQuery.length) {
            if (e !== null) {
              if (e.value) {
                const produtosSelecionados = [...this.selected, ...this.todosProdutos];
                this.selected = [...removerItensDuplicadosPorId(produtosSelecionados)];
              } else {
                this.selected = this.selected.filter((p) => !this.todosProdutos.some((tp) => tp.id === p.id));
              }
            } else if (this.selected.length === this.tamanhoTotalProdutos) {
              this.selected = [];
            }
          } else if (e !== null && this.totalPage > this.pagination.itemsPerPage) {
            if (this.selected.length !== this.tamanhoTotalProdutos
              && this.selected.length !== (this.tamanhoTotalProdutos - this.pagination.itemsPerPage)) {
              this.selected = [...this.todosProdutos];
            } else {
              this.selected = [];
            }
          }
        });
    },
    // filtrarTodosProdutos() {
    //   const params = {};
    //   if (this.searchQuery && this.searchQuery.length) {
    //     params.filtro = this.searchQuery;
    //   }
    //   if (this.categoriaSelecionada) {
    //     params.idCategoria = this.categoriaSelecionada.id;
    //   }
    //   if (this.familiaSelecionada && this.familiaSelecionada.id !== 0) {
    //     params.idFamilia = this.familiaSelecionada.id;
    //   }

    //   return buscarProdutosSelecao(params, this.$resource)
    //     .then((res) => {
    //       this.listaProdutosFiltrada = res.data;
    //     });
    // },
    // filtrarProdutosSelecionados() {
    //   this.listaProdutosFiltrada = this.listaProdutos
    //     .filter((p) => this.selected.includes(p.id));
    // },
    // filtrarProdutosNaoSelecionados() {
    //   this.listaProdutosFiltrada = this.listaProdutos
    //     .filter((p) => !this.selected.includes(p.id));
    // },
    selecionarProdutos() {
      let produtosSelecionados = null;
      if (this.flagLinhaSku || this.tamanhoTotalProdutos !== this.selected.length) {
        produtosSelecionados = copyObject(this.selected);
      }
      if (this.indexLinhaProdutoSelecionada != null && this.indexLinhaProdutoSelecionada >= 0) {
        this.$emit('ModalSelecaoProduto__AtualizarLinhaProduto', produtosSelecionados, this.indexLinhaProdutoSelecionada);
      } else {
        this.$emit('ModalSelecaoProduto__IncluirLinhaProduto', produtosSelecionados, this.flagLinhaSku);
      }
    },
    setarProdutosSelecionados(produtosSelecionados) {
      this.resetaModal();
      this.selected = produtosSelecionados;
    },
    setarLinhaProdutoSelecionada(linhaProdutoSelecionada) {
      this.resetaModal();
      this.linhaProdutoSelecionada = linhaProdutoSelecionada;
    },
    resetaModal() {
      this.selected = [];
      this.listaProdutos = [];
      this.listaProdutosFiltrada = [];
      this.isVisualizarLinhaProduto = false;
      this.iniciarTodosSelecionados = false;
      this.indexLinhaProdutoSelecionada = -1;
      this.linhaProdutoSelecionada = {};
      this.existeErroDuplicidadeLinha = false;
      this.searchQuery = null;
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.tamanhoTotalProdutos = 0;
      this.flagLinhaSku = false;
      this.isEditarLinhaProduto = false;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    exibeMensagemErroDuplicidade(produtosSelecionadosModal) {
      this.existeErroDuplicidadeLinha = true;
      this.listaProdutos.forEach((linhaProduto) => {
        if (produtosSelecionadosModal.find((x) => x.codProdutoComDigito === linhaProduto.codProdutoComDigito)) {
          linhaProduto.isDuplicado = true;
        }
      });
    },
    visualizarProdutosKit(item) {
      this.planejamentoAcaoResource.buscarProdutoskit({ idExterno: item.idExterno })
        .then((res) => {
          if (res.body) {
            setTimeout(() => this.$refs.modalProdutosKit.open(res.body), 2E2);
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (!this.isAmbienteEpoca) {
      this.headers.unshift({ text: this.$tc('label.categoria', 1), value: 'codNomeCategoria', sortable: false });
    }
  },
};
</script>
<style>
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo,
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo input.v-money {
  color: #ff0000ba;
}
.linhaDuplicada {
  background-color: #ef5350 !important;
}
</style>
