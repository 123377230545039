<template>
  <v-col cols="12" md="12" :lg="isVerbaVariavel ? 12 : 6" style="text-align:right">
    <slot name="acoes-padrao" />
    <v-btn @click="salvarAntesDeSolicitar()"
     class="mr-3"
      dark
      color="red"
      v-show="podeSolicitarAprovacao"
      :disabled="!permiteSalvar">
      {{ $t('label.solicitar_aprovacao') }}
    </v-btn>
    <v-btn @click="prepararJustificativaAprovar()"
     class="mr-3"
      dark
      color="primary"
      v-show="podeAprovar">
      {{ $t('label.aprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaReprovar()"
     class="mr-3"
      dark
      color="red"
      v-show="podeAprovar">
      {{ $t('label.reprovar') }}
    </v-btn>
    <v-btn @click="abrirJustificativaAnalise()"
     class="mr-3"
      dark
      color="tertiary lighten-1"
      v-show="podeAprovar">
      {{ $tc('label.enviar_analise', 1) }}
    </v-btn>
    <confirm
      ref="modalConfirmacao"
      :message="msgConfirmacao"
      :maxWidth="'450'"
      :persistent="true"
      hide-disagree
      :agree-label="$t('label.ok')"/>
    <planejamento-acao-form-usuario-aprovador
      ref="modalUsuarioAProvador"
      :title="$tc('title.selecao_aprovador_condicional_acao', 1)"
      :passo-fornecedor="passoFornecedor"
      :passo-diretor="passoDiretor"
      @selecionarUsuarioAprovador="selecionaAprovadorPassoClienteFornecedor"/>
    <planejamento-acao-justificativa
      ref="modalJustificativa"
      :obrigatorio="justificativaObrigatoria"
      :salvarJustificativa="actionJustificativa"
      :id-acao="idAcao"
      :is-aprovacao="isAprovacao"/>
    <modal-redirecionamento
      ref="modalRedirecionamento"
      @INICIAR_FLUXO="iniciarFluxo">
    </modal-redirecionamento>
    <confirm
      ref="modalDuplicidadeCampanha"
      :message="$t('message.confirmacao_campanha_duplicada', { campanhas: this.campanhasDuplicadas })"
      :persistent="true"
      :title="$t('title.duplicidade_campanha')"
      :agree-label="$t('label.ok')"
      hide-disagree
      max-width="65%"
      />
      <planejamento-acao-verba-variavel-modal-nota-fiscal
        ref="modalNotaFiscal"
        :id-acao="idAcao"
        @APROVAR_NOTA_FISCAL="aprovar">
      </planejamento-acao-verba-variavel-modal-nota-fiscal>
  </v-col>
</template>
<script>

import { mapGetters } from 'vuex';
import moment from 'moment';
import { iniciaFluxo } from '@/common/resources/workflow-acao-redirecionamento';
import PlanejamentoAcaoJustificativa from '@/spa/planejamento-acao/PlanejamentoAcaoJustificativa';
import Confirm from '@/shared-components/dialog/Confirm';
import ModalRedirecionamento from '@/shared-components/workflow/ModalRedirecionamento';
import PlanejamentoAcaoFormUsuarioAprovador from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoFormUsuarioAprovador';
import PlanejamentoAcaoVerbaVariavelModalNotaFiscal from './PlanejamentoAcaoVerbaVariavelModalNotaFiscal';

export default {
  props: {
    permiteSalvar: Boolean,
    acaoEdicao: Object,
    edicao: Boolean,
    configuracao: Object,
    isValorPendenteZerado: Boolean,
    indTemEvidencia: {
      type: Boolean,
      default: false,
    },
    uploadEvidenciaObrigatorio: {
      type: Boolean,
      default: false,
    },
    isVerbaVariavel: {
      type: Boolean,
      default: false,
    },
    acaoSalvar: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),

      solicitanteFluxo: false,
      passoFornecedor: {},
      passoDiretor: {},
      idCondicional: null,
      aprovadorFluxo: false,
      justificativaObrigatoria: false,
      actionJustificativa() {},
      idAcao: null,
      msgConfirmacao: null,
      isAprovacao: false,
      campanhasDuplicadas: '',
      listaCampanhasDuplicadas: [],
      iniciouSolicitacaoAprovacao: false,
      condicionalWorkflow: '',
    };
  },
  components: {
    PlanejamentoAcaoJustificativa,
    Confirm,
    ModalRedirecionamento,
    PlanejamentoAcaoFormUsuarioAprovador,
    PlanejamentoAcaoVerbaVariavelModalNotaFiscal,
  },
  computed: {
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    isTenantEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    podeSolicitarAprovacao() {
      return this.edicao && this.acaoEdicao
        && this.acaoEdicao.status === 'EM_CADASTRO' && this.solicitanteFluxo && !this.listaCampanhasDuplicadas.length;
    },
    podeAprovar() {
      return this.acaoEdicao
        && this.acaoEdicao.status === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo && !this.listaCampanhasDuplicadas.length;
    },
  },
  methods: {
    carregaInformacoesFluxo(idAcao) {
      this.idAcao = Number(idAcao);
      if (this.acaoEdicao.status === 'EM_CADASTRO') {
        this.verificarUsuarioSolicitante(idAcao);
      } else {
        this.verificarUsuarioAprovador(idAcao);
        this.buscarStatusFluxo(idAcao);
      }
    },
    verificarUsuarioSolicitante(idAcao) {
      if (this.acaoEdicao && this.acaoEdicao.status === 'EM_CADASTRO') {
        this.workflowAcaoResource.solicitante({ idAcao })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
          });
      }
    },
    solicitarAprovacao() {
      const resourcePassoFornecedor = this.workflowAcaoResource.buscaPassosFornecedor;
      const resourcePassoDiretor = this.workflowAcaoResource.buscaPassosDiretor;
      const { idAcao } = this.$route.params;
      const flagFornecedorAprovador = this.acaoEdicao.indPossuiPassoFornecedor;
      Promise.all([resourcePassoFornecedor({ idAcao }), resourcePassoDiretor({ idAcao })])
        .then((response) => {
          const passosFornecedor = response[0].data;
          const passosDiretor = response[1].data;
          const passosFornecedorComUsuarioAprovador = passosFornecedor.filter((passo) => passo.usuarios.length);
          if ((passosFornecedor.length === 0 || !this.acaoEdicao.indPossuiPassoFornecedor)
            && passosDiretor.length === 0) {
            this.iniciarFluxo();
          } else if (passosDiretor.length > 1) {
            const msg = this.$t('errors.acordo.multiplos_passos_diretor');
            this.$toast(msg);
          } else if ((passosFornecedor.length && passosFornecedor.length !== passosFornecedorComUsuarioAprovador.length
            && flagFornecedorAprovador)
            || (passosDiretor.length && !passosDiretor[0].usuarios.length)) {
            let condicionalSemUsuarioAprovador = '';
            if (passosDiretor.length && !passosDiretor[0].usuarios.length) {
              condicionalSemUsuarioAprovador = condicionalSemUsuarioAprovador.concat('<ul><li>').concat(this.$t('label.diretor_categoria')).concat('</li></ul>');
            }
            if (passosFornecedor.length && passosFornecedor.length !== passosFornecedorComUsuarioAprovador.length
              && flagFornecedorAprovador) {
              condicionalSemUsuarioAprovador = condicionalSemUsuarioAprovador.concat('<ul><li>Fornecedor</li></ul>');
            }
            this.msgFluxoSemAprovador = this.$t('message.nao_tem_usuario_condicional_aprovador', { condicional_nao_encontrada: condicionalSemUsuarioAprovador });
            this.msgConfirmacao = '';
            setTimeout(() => this.$refs.modalConfirmacao
              .openWithParams(
                this.$t('title.atencao'),
                this.msgFluxoSemAprovador,
                null,
              ));
          } else {
            // Implementado suporte para apenas 1 passo fornecedor
            const [passoFor] = passosFornecedor;
            const [passoDir] = passosDiretor;
            this.passoFornecedor = passoFor && passoFor.usuarios.length > 0
              && this.acaoEdicao.indPossuiPassoFornecedor ? passoFor : null;
            this.passoDiretor = passoDir && passoDir.usuarios.length > 0
            && this.acaoEdicao.calculoBonificacao === 'VALOR_FIXO' ? passoDir : null;
            this.$refs.modalUsuarioAProvador.open();
          }
        });
    },
    abreModalRedirecionamento() {
      this.workflowAcaoMagaluResource.buscarFluxoAcao()
        .then((res) => {
          this.fluxo = res.data;
          this.$refs.modalRedirecionamento.fluxo = this.fluxo;
          this.$refs.modalRedirecionamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    iniciarFluxo(dados = null) {
      const params = {
        idAcao: this.$route.params.idAcao,
      };

      if (dados != null) {
        const { fluxo } = dados;

        if (fluxo != null) {
          const idsPassosDesativados = [];
          fluxo.passosFluxo.forEach((passo) => {
            if (!passo.indAtivo) {
              idsPassosDesativados.push(passo.id);
            }
          });

          if (idsPassosDesativados.length === fluxo.passosFluxo.length) {
            this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
            return;
          }

          params.idsPassosDesativados = idsPassosDesativados;
        }
      }

      iniciaFluxo(params, this.$resource)
        .then(() => {
          if (this.$route.params.from && this.$route.params.modalDashboard && this.$route.params.from === 'dashboard-inicial') {
            this.$router.push({ name: this.$route.params.from, params: { modalDashboard: this.$route.params.modalDashboard } });
          } else {
            this.$router.push({ name: 'planejamento-acao' });
          }
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch((err) => {
          this.$error(this, err);
          this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
        });
    },
    selecionaAprovadorPassoClienteFornecedor(dadosCondicional) {
      const { idAcao } = this.$route.params;
      const params = {
        idAcao,
        ...dadosCondicional,
      };
      const resource = this.workflowAcaoResource.associaUsuariosCondicionalAprovador;
      resource(params, params)
        .then(() => this.iniciarFluxo())
        .catch((err) => this.$error(this, err));
    },
    verificarUsuarioAprovador(idAcao) {
      if (this.acaoEdicao && this.acaoEdicao.status === 'AGUARDANDO_APROVACAO') {
        this.workflowAcaoResource.aprovador({ idAcao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            }
          });
      }
    },
    verificaSeUsuarioAprovadorNFComplementoInformacoesAprovar() {
      const params = {
        idAcao: this.acaoEdicao.id,
        idPerfil: this.usuarioLogado.idPerfil,
      };
      this.workflowAcaoMagaluResource
        .verificarSeUsuarioAprovadorNFComplementoInformacoes(params)
        .then((res) => {
          this.condicionalWorkflow = res.data;
          if (this.condicionalWorkflow) {
            this.$refs.modalNotaFiscal.open();
          } else {
            this.abrirJustificativaAprovar();
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    prepararJustificativaAprovar() {
      if (this.acaoEdicao.calculoBonificacao === 'NOTA_FISCAL') {
        this.verificaSeUsuarioAprovadorNFComplementoInformacoesAprovar();
      } else {
        this.abrirJustificativaAprovar();
      }
    },
    abrirJustificativaAprovar() {
      this.justificativaObrigatoria = false;
      this.actionJustificativa = this.aprovar;
      this.isAprovacao = true;
      this.$refs.modalJustificativa.open();
    },
    aprovar(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.aprovarPasso({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaReprovar() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.reprovar;
      this.isAprovacao = false;
      this.$refs.modalJustificativa.open();
    },
    reprovar(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.reprovar({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    abrirJustificativaAnalise() {
      this.justificativaObrigatoria = true;
      this.actionJustificativa = this.enviarAnalise;
      this.isAprovacao = false;
      this.$refs.modalJustificativa.open();
    },
    enviarAnalise(justificativa) {
      const { idAcao } = this.$route.params;
      this.workflowAcaoResource.enviarAnalise({ idAcao },
        { observacao: justificativa })
        .then(() => {
          this.$router.push({ name: 'planejamento-acao' });
          this.$toast(this.$t('message.acao_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    buscarStatusFluxo(idAcao) {
      this.workflowAcaoResource.status({ idAcao })
        .then((response) => {
          this.acaoEdicao.fluxo = response.data;
          const { passoAtual } = this.acaoEdicao.fluxo;
          this.idCondicional = passoAtual.idCondicional;
        });
    },
    mostrarRedirecionamento(item) {
      return item.solicitanteFluxo && item.status === 'AGUARDANDO_APROVACAO';
    },
    salvarPassosRedirecionamento(fluxo, acao) {
      const idsPassosDesativados = [];
      fluxo.passos.forEach((passo) => {
        if (!passo.indAtivo) {
          idsPassosDesativados.push(passo.id);
        }
      });

      if (idsPassosDesativados.length === fluxo.passos.length) {
        this.$toast(this.$t('errors.fluxo.passo_elegivel_obrigatorio'));
        return;
      }

      const params = {
        id_acao: acao.id,
        ids_passos_desativados: idsPassosDesativados,
      };

      atualizarPassos(params, this.$resource)
        .then(() => {
          this.$toast(this.$t('message.fluxo_redirecionado_sucesso'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    async salvarAntesDeSolicitar() {
      if (this.uploadEvidenciaObrigatorio && !this.indTemEvidencia) {
        this.$toast(this.$t('label.upload_anexo_obrigatorio'));
        return;
      }
      if (this.isAmbienteKabum && this.configuracao.configuracao.passo2.indHabilitarRateioContabil
       && !this.isValorPendenteZerado) {
        const msg = this.$t('errors.acao.somatoria_items_rateio_igual_campanha');
        this.$toast(msg);
        return;
      }
      if (this.iniciouSolicitacaoAprovacao) {
        $logger.log('Evitando dupla solicitacao de acao', Date.now());
        return;
      }
      $logger.log('Solicitando aprovacao da acao', Date.now());

      this.iniciouSolicitacaoAprovacao = true;

      try {
        await this.acaoSalvar(true);
        await this.solicitarAprovacao();
      } catch (e) {
        $logger.log('Erro ao salvar acao', e);
      }

      this.iniciouSolicitacaoAprovacao = false;
    },
    verificaDuplicidadeCampanhas() {
      if (!this.edicao || !this.acaoEdicao) {
        return;
      }
      let idAcao = null;
      if (this.$route.params.id) {
        idAcao = this.$route.params.id;
      } else {
        idAcao = this.$route.params.idAcao;
      }

      this.planejamentoAcaoListagemResource.buscaAcoesDuplicadas({ idAcao })
        .then((res) => {
          this.listaCampanhasDuplicadas = res.data;
          if (this.listaCampanhasDuplicadas.length && this.acaoEdicao.status === 'EM_CADASTRO') {
            this.campanhasDuplicadas = ''.concat('<table class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas" width=100%><tr>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">ID</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">Status</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">Tipo</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">Modalidade</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">UN</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">Vigência</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">Base de Apuração</th>')
              .concat('<th class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">Produto</th>')
              .concat('</tr>');
            this.listaCampanhasDuplicadas.forEach((campanhaDuplicada) => {
              this.campanhasDuplicadas = this.campanhasDuplicadas
                .concat('<tr>')
                .concat(`<td> <a class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas_bt" id="${campanhaDuplicada.id}">${campanhaDuplicada.id}</a></td>`)
                .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${campanhaDuplicada.status}</td>`)
                .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${campanhaDuplicada.tipo_acao}</td>`)
                .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${campanhaDuplicada.calculo_bonificacao}</td>`)
                .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${campanhaDuplicada.tipo_unidade_negocio}</td>`)
                .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${moment(campanhaDuplicada.dta_inicio, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')} a ${moment(campanhaDuplicada.dta_fim, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}</td>`)
                .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${campanhaDuplicada.id_fornecedor}</td>`);

              if (campanhaDuplicada.tipo_produto === 'CATEGORIA') {
                this.campanhasDuplicadas = this.campanhasDuplicadas
                  .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas ">${campanhaDuplicada.id_categoria}`)
                  .concat('</tr>');
              } else {
                this.campanhasDuplicadas = this.campanhasDuplicadas
                  .concat(`<td class="PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas">${campanhaDuplicada.id_categoria}
                    <div class="tooltip fa fa-info-circle">
                      <span class="tooltiptext">${this.$t('message.duplicidade_campanha_detectada', { tipo_duplicidade: this.retornaTipoDuplicidade(campanhaDuplicada.tipo_produto) })}</span>
                    </div></td>`)
                  .concat('</tr>');
              }
            });
            this.campanhasDuplicadas = this.campanhasDuplicadas.concat(`</table>${this.$t('message.confirmacao_campanha_duplicada_avaliar_campanha')}`);
            setTimeout(() => this.$refs.modalDuplicidadeCampanha.openWithParams(
              this.$t('title.duplicidade_campanha'),
              null,
              () => {},
            ));
          }

          setTimeout(() => document.querySelectorAll('.PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas_bt').forEach((item) => {
            item.addEventListener('click', this.abreNovaAbaPlanejamento);
          }), 1000);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    retornaTipoDuplicidade(tipo) {
      switch (tipo) {
        case 'FAMILIA':
          return `${'na '}${this.$t('label.familia').toLowerCase()}`;
        case 'PRODUTO':
          return `${'no '}${this.$t('label.sku').toLowerCase()}`;
        default:
          return '';
      }
    },
    abreNovaAbaPlanejamento(item) {
      const route = this.$router.resolve({ name: 'editarAcaoVerbaVariavel', params: { idAcao: item.target.id, from: 'dashboard-inicial', carregaPassoAcoes: true } });
      const newTab = window.open(route.href, '_blank');
      if (newTab) {
        localStorage.setItem('idAcaoDuplicidade', item.target.id);
      }
    },
  },
  mounted() {
    const { novaAcao } = this.$router.currentRoute.params;
    this.verificaDuplicidadeCampanhas();
    if (novaAcao) {
      const numeroNd = this.acaoEdicao ? this.acaoEdicao.mapaCamposDinamicos.num_nota_debito : null;
      const tipoPagamento = this.acaoEdicao ? this.acaoEdicao.tipoPagamento : null;
      if (numeroNd) {
        this.msgConfirmacao = this.$t('message.acao_criada_sucesso_num_nd', { numNd: numeroNd });
      } else if (tipoPagamento === 'NOTA_FISCAL') {
        this.msgConfirmacao = this.$t('message.acao_criada_sucesso_nota_fiscal');
      } else {
        this.msgConfirmacao = this.$t('message.acao_criada_sucesso_sem_num_nd', { numNd: numeroNd });
      }
      setTimeout(() => this.$refs.modalConfirmacao.open());
    }
  },
};
</script>
<style>
  .PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas {
    border-collapse: collapse;
    border:1px solid black;
  }
  .PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas th{
    padding: 8px;
    text-align: center;
  }
  .PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas td {
    padding: 8px;
    text-align: center;
  }
  .PlanejamentoAcaoVerbaVariavelFormAcoesFluxo__tbAcoesDuplicadas_bt {
    color: rgb(63, 117, 244);
  }
  .tooltip {
  position: relative;
  display: inline-block;
  color:gray;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -150px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
