<template>
  <div>
    <v-dialog v-model="exibeModalUploadProdutos" max-width="1100px" eager persistent>
      <v-card id="dialog">
        <v-overlay
          :z-index="zIndex"
          :value="dialog"
        >
          <v-card>
            <v-card-text>
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
            <v-card-text v-if="message">
              {{ $tc('message.upload_massivo_produtos_loading_1') }}
            </v-card-text>
            <v-card-text v-if="!message">
              {{ $tc('message.upload_massivo_produtos_loading_2') }}
            </v-card-text>
          </v-card>
        </v-overlay>
        <v-card style="text-align: left">
          <v-card-title>
            <div class="card-title">
              {{ $tc('label.upload_massivo_produtos', 2) }}
            </div>
          </v-card-title>
          <v-container class="pa-4">
            <v-row>
              <v-col cols="12" >
                <v-form ref="formModalUploadProdutos" lazy-validation autocomplete="off">
                  <v-row >
                    <v-col cols="12" align-self="center"  style="text-align: left">
                      <h4 style="color: orange">
                        <span v-html="`${$t('message.upload_massivo_produtos_msg_modal')}`" />
                        <a :href="link" target="_blank" rel="noopener noreferrer" class="text-decoration-underline">
                          {{ $t('label.link') }}
                        </a>
                      </h4>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card style="text-align: left">
          <v-card-title>
            <div class="card-title">
              {{ $tc('title.upload_massivo_produtos_orientações') }}
            </div>
          </v-card-title>
          <v-container class="pa-4">
            <v-row>
              <v-col cols="12" >
                <v-form ref="formModalUploadProdutos" lazy-validation autocomplete="off">
                  <v-row>
                    <v-col cols="12" sm="10" align-self="center" style="text-align: left">
                      {{ $tc('message.upload_massivo_produtos_orientacao_1') }}
                      <v-btn
                        id="btn_upload_produto"
                        @click="downloadArquivoUpload()"
                        icon
                        small
                        plain>
                        <v-icon
                          class="fa-sm">
                          download
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="10" align-self="center" style="text-align: left">
                      {{ $tc('message.upload_massivo_produtos_orientacao_2') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="10" align-self="center" style="text-align: left">
                      {{ $tc('message.upload_massivo_produtos_orientacao_3') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" align-self="center" style="text-align: left">
                      <upload-file
                        class="md-hide-medium"
                        id="uploadFileDropzoneId"
                        @UPLOADFILE_SUCCESS="uploadSucesso"
                        @UPLOADFILE_ERROR="uploadError"
                        @UPLOADFILE_SENDING="enviandoArquivo"
                        :accepted-file-types="tipoImportacaoCampanhaProduto.arquivosSuportados"
                        :maxFileSizeInMB="50"
                        :url="uploadFileUrl"
                        ></upload-file>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card style="text-align: left" v-if="erros.length">
          <v-card-title>
            <div class="card-title">
              {{ $tc('title.erro', 2) }}
            </div>
          </v-card-title>
          <v-container class="pa-4">
            <template>
              <v-data-table
                :headers="cabecalhoErros"
                :items="erros"
                :items-per-page="5"
                class="elevation-1"
              ></v-data-table>
            </template>
          </v-container>
        </v-card>
        <v-card style="text-align: left" v-if="linhas.length">
          <v-container class="pa-4" style="width: 80%">
            <template>
              <v-row align="center" style="background-color: lightgreen;">
                <v-col cols="12" sm="1" fill-height align-self="center" style="text-align: right;">
                  <v-icon large color="green">check_circle</v-icon>
                </v-col>
                <v-col cols="12" sm="10" align-self="center" style="text-align: left;">
                  <span v-html="`${$t('message.upload_massivo_produtos_arquivo_validado')}`" />
                </v-col>
              </v-row>
              <v-row align="center" style="background-color: lightgreen;">
                <v-col cols="12" sm="1" align-self="center" style="text-align: left;">
                </v-col>
                <v-col cols="12" sm="10" class="mb-2" align-self="center" style="text-align: center;">
                  <v-btn
                    id="btn_confirmar"
                    color="primary"
                    @click.native="confirmarUploadProdutos()">
                    {{ $t('label.confirmar') }}
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card>
        <v-card>
          <v-row>
            <v-col cols="12" sm="12" align-self="center" style="text-align: right">
              <v-btn
                id="btn_cancelar"
                color="secundary"
                @click.native="cancelarUploadProdutos()">
                {{ $t('label.cancelar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { basePath } from '@/common/resources/importacoes';
import UploadFile from '@/shared-components/upload/UploadFile';
import exportacao from '@/common/functions/exportacao';

export default {
  name: 'PlanejamentoAcaoVerbaVariavelModalUploadProdutos',
  components: {
    UploadFile,
  },
  props: {
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacaoCampanhaProduto() {
      return this.tiposImportacao.find((el) => el.nome === 'importacao_campanha_produto');
    },
    uploadFileUrl() {
      return `${basePath}importacao/campanha-produto`;
    },
    verbaValorPorPeca() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'VALOR_POR_PECA';
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        clearInterval(this.interval);
        return;
      }
      this.changeMessage();
    },
  },
  data() {
    return {
      dialog: false,
      message: true,
      interval: null,
      parametros: null,
      zIndex: 1,
      cabecalhoErros: [
        {
          text: this.$tc('label.linha', 1), value: 'linha', sortable: false, width: '20%',
        },
        { text: this.$tc('label.descricao', 1), value: 'descricao', sortable: false },
      ],
      erros: [],
      linhas: [],
      exibeModalUploadProdutos: false,
      link: 'https://docs.google.com/spreadsheets/d/1_c-W7J-aELs4X6B9d71GB3r-1CHO5y12/edit?usp=sharing&ouid=106837834410305134017&rtpof=true&sd=true',

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    open(params) {
      this.parametros = params;
      this.scrollToTop();
      this.exibeModalUploadProdutos = true;
    },
    close() {
      this.resetarModal();
      this.exibeModalUploadProdutos = false;
    },
    cancelarUploadProdutos() {
      this.close();
    },
    confirmarUploadProdutos() {
      this.$emit('ModalUploadProdutos__ConfirmarUploadProdutos', this.linhas);
      this.close();
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    downloadArquivoUpload() {
      const params = {};

      if (this.verbaValorPorPeca) {
        params.is_valor_por_peca = true;
      }

      if (this.acao.possuiValorMaximoNd) {
        params.possui_valor_maximo_nd = true;
      }

      if (this.acao.possuiGatilhoGerarRecebimento) {
        params.possui_gatilho = true;
      }

      if (this.acao.tipoCalculoValorMaximoND === 'CALCULO_CAMPANHA_ND_MAXIMO') {
        params.possui_valor_maximo_nd_campanha = true;
      }

      if (this.acao.tipoCalculoValorMaximoND === 'CALCULO_PRODUTO_ND_MAXIMO') {
        params.possui_valor_maximo_nd_produto = true;
      }

      exportacao.exportar(null, 'modelo_upload_campanha_produto', this, params);
    },
    enviandoArquivo(data, xhr, formData) {
      this.dialog = true;
      const parametros = { ...this.parametros };

      if (this.verbaValorPorPeca) {
        parametros.isValorPorPeca = true;
      }

      if (this.acao.possuiValorMaximoNd) {
        parametros.possuiValorMaximoNd = true;
      }

      if (this.acao.possuiGatilhoGerarRecebimento) {
        parametros.possuiGatilho = true;
      }

      if (this.acao.tipoCalculoValorMaximoND === 'CALCULO_CAMPANHA_ND_MAXIMO') {
        parametros.possuiValorMaximoNdCampanha = true;
      }

      if (this.acao.tipoCalculoValorMaximoND === 'CALCULO_PRODUTO_ND_MAXIMO') {
        parametros.possuiValorMaximoNdProduto = true;
      }

      formData.append('id_tipo_importacao', this.tipoImportacaoCampanhaProduto.id);
      formData.append('async', false);
      formData.append('parametros', JSON.stringify(parametros));
    },
    uploadSucesso(arquivo, response) {
      const { md5 } = response;
      this.$emit('ModalUploadProdutos__salvarMd5ArquivoProduto', md5);
      if (response && response.planilhaPossuiErro !== undefined) {
        if (!response.erros.length && !response.linhas.length) {
          response.erros.push({ 1: 'planilha.vazia' });
          response.planilhaPossuiErro = true;
        }
        if (response.planilhaPossuiErro) {
          this.linhas = [];
          this.erros = this.separateErros(response);
        } else {
          this.erros = [];
          this.linhas = [...response.linhas];
        }
        this.scrollToBottom();
      } else {
        this.$toast(this.$t('message.erro_tente_importar_novamente'));
      }
      setTimeout(() => {
        this.dialog = false;
      }, 800);
    },
    uploadError(file, msg) {
      this.$toast(msg || this.$t('message.erro_tente_importar_novamente'));
      setTimeout(() => {
        this.dialog = false;
      }, 1000);
    },
    separateErros(resultado) {
      let erros = [];
      if (resultado.erros) {
        erros = resultado.erros.map((e) => {
          const linha = parseInt(Object.keys(e)[0], 10);
          const erroMap = e[linha].split(' ');
          const param = erroMap[1] ? erroMap[1].replace(/_/g, ' ').replace(/\|/g, ', ') : '';

          const descricao = this.$t(`errors.importacao.${erroMap[0]}`, { param });
          return { linha, descricao };
        });

        erros.sort(
          (e1, e2) => {
            if (e1.linha > e2.linha) {
              return 1;
            }
            if (e2.linha > e1.linha) {
              return -1;
            }
            if (e1.descricao > e2.descricao) {
              return 1;
            }
            if (e2.descricao > e1.descricao) {
              return -1;
            }
            return 0;
          },
        );
      }
      return erros;
    },
    changeMessage() {
      this.interval = setInterval(() => {
        this.message = !this.message;
      }, 5000);
    },
    resetarModal() {
      this.erros = [];
      this.linhas = [];
      this.parametros = null;
    },
    scrollToTop() {
      setTimeout(() => {
        const element = document.getElementById('dialog');
        element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
      }, 100);
    },
    scrollToBottom() {
      setTimeout(() => {
        const element = document.getElementById('dialog');
        element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      }, 1000);
    },
  },
  mounted() {
  },
};
</script>
<style>
.botoes .v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover),
.botoes .v-btn__content {
  opacity: 1 !important;
}
</style>
