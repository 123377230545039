<template>
  <v-card class="PlanejamentoAcaoFormCampos__Card--treslinhas">
    <v-form
      ref="formOrigemAcao"
      lazy-validation
      autocomplete="off">
      <v-card-title>
        <div class="card-title">
          {{ $tc('label.fornecedor', 1) }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md v-if="carregouSecao" class="pa-0">
          <v-row dense>
            <v-col cols="12">
              <v-chip-group
                id="planejamento-origem-acao"
                mandatory
                v-model="origemAcao"
                active-class="primary"
                dark
                @change="limparOrigem"
              >
                <v-chip
                  v-for="origemAcao in origemAcaoConfiguracao"
                  label
                  :key="origemAcao.valor"
                  :disabled="somenteLeitura || unicaOrigemAcao || isCalculoBonificacaoValorFixo"
                  :value="origemAcao.valor"
                >
                  {{ origemAcao.texto }}
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" sm="11">
              <v-autocomplete
                id="planejamento-autocomplete-grupo-fornecedor"
                class="custom-autocomplete pt-0"
                v-if="exibeOrigemGrupoFornecedor"
                v-model="formulario.grupoFornecedor"
                return-object
                :rules="rules.requiredFunction('grupoFornecedor', 'nomExtensao')"
                :label="labelGrupoFornecedor"
                :items="gruposFornecedores"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                :item-text="isAmbienteEpoca ? 'idExternoNome' : 'codNome'"
                item-value="id"
                required
                :disabled="somenteLeitura"
                :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
                clearable
                @input="grupoFornecedorAlterado"
                @click:append="() => triggerSelecao('planejamento-autocomplete-grupo-fornecedor')"
                @click.native="(i) => buscaAutocomplete(i, buscaGruposFornecedores)"
                :search-input.sync="triggerGrupoFornecedor">
              </v-autocomplete>
              <v-autocomplete
                id="planejamento-autocomplete-fornecedor"
                class="custom-autocomplete pt-0"
                v-else
                v-model="formulario.fornecedor"
                return-object
                :filter="filtrarFornecedores"
                :rules="rules.requiredFunction('fornecedor', 'codNomeCnpj')"
                :items="fornecedores"
                :label="isCalculoBonificacaoValorFixo ? `${$tc('label.fornecedor', 1)} *` : `${$tc('label.base_apuracao', 1)} *`"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="codNomeCnpj"
                item-value="id"
                required
                :disabled="somenteLeitura"
                :placeholder="somenteLeitura ? null : $tc('message.digite_para_pesquisar', 1)"
                clearable
                @input="fornecedorAlterado"
                @click:append="() => triggerSelecao('planejamento-autocomplete-fornecedor')"
                @click.native="(i) => buscaAutocomplete(i, buscaFornecedores)"
                :search-input.sync="triggerFornecedor">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="1" style="margin-top: 1em" v-if="exibeIconeInformacao">
              <v-tooltip bottom >
                <template v-slot:activator="{ on }">
                  <v-icon
                    left
                    v-on="on">
                    help
                  </v-icon>
                </template>
                <span>{{$tc('label.base_apuracao_help')}}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="11" :key="panelKey"
              v-if="exibeFornecedorPagador && exibeCnpjNotaDebito">
              <v-autocomplete
                id="planejamento-autocomplete-fornecedorpagador"
                class="custom-autocomplete"
                v-model="formulario.fornecedorPagador"
                return-object
                :rules="rules.requiredFunctionOpcional('fornecedorPagador', 'codNomeCnpj', fornecedorPagadorObrigatorio)"
                :items="fornecedoresPagadores"
                :label="labelFornecedorPagador"
                :no-data-text="$tc('message.nenhum_registro', 1)"
                item-text="codNomeCnpj"
                item-value="id"
                :required="fornecedorPagadorObrigatorio"
                :disabled="somenteLeitura || desabilitaFornecedorPagador"
                :placeholder="(somenteLeitura || desabilitaFornecedorPagador) ? null : $tc('message.digite_para_pesquisar', 1)"
                :clearable="true">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="1" style="margin-top: 1.5em"
              v-if="exibeFornecedorPagador && exibeIconeInformacao">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      left
                      v-on="on">
                      help
                    </v-icon>
                  </template>
                  <span>{{$tc('label.cnpj_nota_debito_help')}}</span>
                </v-tooltip>
            </v-col>
            <v-col cols="12" :sm="isCalculoBonificacaoValorFixo ? 6 : 12">
              <v-text-field
                id="plan_acao_prazo_vencimento"
                v-model="formulario.prazoVencimentoAcao"
                :label="`${$tc('label.prazo_pagamento_dias', 1)} *`"
                type="Number"
                class="plan_acao_prazo_vencimento pt-0"
                min="1"
                :disabled="somenteLeitura"
                :hint="$tc('label.prazo_vencimento_padrao', 1)"
                :persistent-hint="true"
                :rules="[rules.required, v => rules.valorMinimo(v, 1)]"
                required
              />
            </v-col>
            <v-col cols="12" v-if="exibeRadioQtdNds">
              <v-radio-group
                id="plan_acao_qtd_nds"
                v-model="formulario.qtdNds"
                :label="`${$tc('label.quantidade_nds', 1)} *`"
                dense
                class="plan_acao_qtd_nds"
                :disabled="somenteLeitura"
                :hint="$tc('message.multiplas_nds.qtd_nds_multiplas', 1)"
                :persistent-hint="formulario.qtdNds === 'MULTIPLAS'"
                :rules="[rules.required]">
                <v-radio :label="$tc('label.unica', 1)" class="my-0" value='UNICA'></v-radio>
                <v-radio :label="$tc('label.multipla', 2)" class="my-0" value='MULTIPLAS'></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-radio-group
                id="plan_acao_tipo_pagamento"
                v-model="formulario.tipoPagamento"
                :label="`${$tc('label.tipo_pagamento', 1)} *`"
                dense
                class="mt-0"
                :disabled="somenteLeitura"
                :rules="[rules.required]">
                <template v-for="(p, index) in tiposPagamentoPermitidos">
                  <v-radio
                    :key="index"
                    class="my-0"
                    :label="p.nome"
                    :value="p.valor">
                  </v-radio>
                  <v-text-field
                    v-if="isTipoPagamentoAvisoCredito && p.valor === 'AVISO_CREDITO'"
                    id="plan_acao_numero_pedido"
                    v-model="formulario.numeroPedido"
                    class="pl-8"
                    :label="`${$tc('label.numero_pedido', 1)} *`"
                    type="Number"
                    min="0"
                    :disabled="somenteLeitura"
                    :rules="[rules.required, v => rules.valorMinimo(v, 0)]"
                    required
                  />
                </template>
              </v-radio-group>
            </v-col>
          </v-row>
          <confirm ref="confirm"
            :message="dialog.message"
            :hide-disagree="dialog.hideDisagree"
            :persistent="true"
            :agree-label="dialog.agreeLabel"
            @agree="dialog.agree"
            @disagree="dialog.disagree">
          </confirm>
          <confirm
            ref="confirmAlteracaoOrigem"
            :message="$t('message.alteracao_origem_contrato')"
            :persistent="true"
            :maxWidth="'50%'"
            @agree="excluirListaProduto"
            @disagree="retornarOrigem">
          </confirm>
          <confirm
            ref="confirmAlteracaoConfiguracaoVinculo"
            :message="$t('message.alteracao_configuracao_campanha_vinculo')"
            :persistent="true"
            :maxWidth="'50%'"
            @agree="alterarConfiguracaoVinculo"
            @disagree="retornarConfiguracaoVinculo">
          </confirm>
        </v-container>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { buscaAutocomplete, triggerSelecao } from '@/common/functions/autocomplete-utils';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  props: {
    somenteLeitura: Boolean,
    novaAcao: Boolean,
    acao: Object,
    isCopiaAcao: Boolean,
    configuracao: {
      type: Object,
      required: true,
    },
    getListaProdutos: Function,
  },
  components: {
    Confirm,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),
      triggerGrupoFornecedor: null,
      triggerFornecedor: null,
      timeoutTrigger: null,
      carregouSecao: false,

      formulario: {
        grupoFornecedor: null,
        fornecedor: null,
        prazoVencimentoAcao: null,
        tipoPagamento: null,
        indPossuiPassoFornecedor: true,
        fornecedorPagador: null,
        qtdNds: 'UNICA',
      },
      gruposFornecedores: [],
      fornecedores: [],
      listaTiposPagamento: [],
      origemAcao: 'GRUPO_FORNECEDOR',
      itensOrigemAcao: [
        {
          valor: 'GRUPO_FORNECEDOR',
          texto: this.$tc('label.grupo_fornecedor', 1),
        },
        {
          valor: 'FORNECEDOR',
          texto: this.$tc('label.fornecedor', 1),
        },
      ],
      fornecedoresPagadores: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredFunctionOpcional: (objLabel, valueLabel, obrigatorio) => [
          () => {
            if (!obrigatorio) {
              return true;
            }

            const objeto = this.formulario[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
        valorMinimo: (value, min) => value >= min || this.$t('errors.valor.minimo', { min }),
        requiredFunction: (objLabel, valueLabel) => [
          () => {
            const objeto = this.formulario[objLabel] || {};
            const val = objeto[valueLabel];
            const msg = this.$t('message.campo_obrigatorio');
            return !!(val) || msg;
          },
        ],
      },
      dialog: {
        message: '',
        hideDisagree: false,
        agreeLabel: this.$t('label.sim'),
        agree: () => {},
        disagree: () => {},
      },
      fornecedoresGrupo: [],
      grupoFornecedorPreSelecionado: null,
      fornecedorPreSelecionado: null,
      grupoFornecedorAnterior: null,
      fornecedorAnterior: null,
      listaProdutoReferencia: null,
      panelKey: 0,
    };
  },
  watch: {
    triggerGrupoFornecedor(val) {
      const novoGrupoFornecedor = this.formulario.grupoFornecedor;
      if (novoGrupoFornecedor && novoGrupoFornecedor.nomExtensao === val) {
        const listaProdutos = this.getListaProdutos();
        if (listaProdutos.length) {
          this.listaProdutoReferencia = listaProdutos.at(0);
          const { fornecedor, grupoFornecedor } = this.acao;
          if (grupoFornecedor) {
            this.grupoFornecedorPreSelecionado = grupoFornecedor;
          }
          if (fornecedor) {
            this.fornecedorPreSelecionado = fornecedor;
          }
          if (this.isAmbienteEpoca && this.listaProdutoReferencia
              && novoGrupoFornecedor.id === this.listaProdutoReferencia.idGrupoFornecedor) {
            return;
          }
          if (this.isAcaoEmCadastro && (((grupoFornecedor && novoGrupoFornecedor.id !== grupoFornecedor.id)
          || this.listaProdutoReferencia.idFornecedor
          || (this.listaProdutoReferencia.idGrupoFornecedor
          && this.listaProdutoReferencia.idGrupoFornecedor !== novoGrupoFornecedor.id))
          && (!novoGrupoFornecedor.idsFornecedor.includes(this.listaProdutoReferencia.idFornecedor)
              || (this.isAmbienteEpoca && this.listaProdutoReferencia.idGrupoFornecedor !== novoGrupoFornecedor.id)))) {
            if (this.acao.idContratoVinculado) {
              this.$refs.confirmAlteracaoConfiguracaoVinculo.open();
            } else {
              this.$refs.confirmAlteracaoOrigem.open();
            }
          }
        }
        const grupoFornecedorAcaoAnterior = this.grupoFornecedorPreSelecionado;
        this.grupoFornecedorAnterior = grupoFornecedorAcaoAnterior || novoGrupoFornecedor;
        this.grupoFornecedorPreSelecionado = novoGrupoFornecedor;
        if (this.isCalculoBonificacaoValorFixo) {
          if (this.acao.idContratoVinculado) {
            this.acao.idContratoVinculadoAntigo = this.acao.idContratoVinculado;
            this.acao.justificativaVinculoContratoAntigo = this.acao.justificativaVinculoContrato;
          }
          this.validarVinculoDeContrato(this.grupoFornecedorPreSelecionado.idsFornecedor.at(0));
        }
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaGruposFornecedores(val);
      }, 500);
    },
    triggerFornecedor(val) {
      const novoFornecedor = this.formulario.fornecedor;
      if (novoFornecedor && novoFornecedor.codNomeCnpj === val) {
        const listaProdutos = this.getListaProdutos();
        if (listaProdutos.length) {
          this.listaProdutoReferencia = listaProdutos.at(0);
          const { fornecedor, grupoFornecedor } = this.acao;
          if (grupoFornecedor) {
            this.grupoFornecedorPreSelecionado = grupoFornecedor;
          }
          if (fornecedor) {
            this.fornecedorPreSelecionado = fornecedor;
          }
          if (this.isAcaoEmCadastro && ((this.listaProdutoReferencia.idGrupoFornecedor && novoFornecedor.idGrupoFornecedor
          && this.listaProdutoReferencia.idGrupoFornecedor !== novoFornecedor.idGrupoFornecedor)
          || (fornecedor && fornecedor.id !== novoFornecedor.id)
          || (this.listaProdutoReferencia.idFornecedor
          && this.listaProdutoReferencia.idFornecedor !== novoFornecedor.id))) {
            if (this.acao.idContratoVinculado) {
              this.$refs.confirmAlteracaoConfiguracaoVinculo.open();
            } else {
              this.$refs.confirmAlteracaoOrigem.open();
            }
          }
        }
        const fornecedorAcaoAnterior = this.fornecedorPreSelecionado;
        this.fornecedorAnterior = fornecedorAcaoAnterior || novoFornecedor;
        this.fornecedorPreSelecionado = novoFornecedor;
        if (this.isCalculoBonificacaoValorFixo) {
          if (this.acao.idContratoVinculado) {
            this.acao.idContratoVinculadoAntigo = this.acao.idContratoVinculado;
            this.acao.justificativaVinculoContratoAntigo = this.acao.justificativaVinculoContrato;
          }
          this.validarVinculoDeContrato(this.fornecedorPreSelecionado.id);
        }
        return;
      }
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscaFornecedores(val);
      }, 500);
    },
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
      'chaveEmpresa',
    ]),
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    origemAcaoConfiguracao() {
      const { origemAcao } = this.configuracao;
      if (this.isAmbienteEpoca && this.isCalculoBonificacaoValorFixo) {
        return this.itensOrigemAcao
          .filter((item) => origemAcao.indexOf(item.valor) >= 0 && item.valor === 'GRUPO_FORNECEDOR');
      }
      if (this.isCalculoBonificacaoValorFixo) {
        return this.itensOrigemAcao
          .filter((item) => origemAcao.indexOf(item.valor) >= 0 && item.valor !== 'GRUPO_FORNECEDOR');
      }

      return this.itensOrigemAcao
        .filter((item) => origemAcao.indexOf(item.valor) >= 0);
    },
    exibeOrigemGrupoFornecedor() {
      return this.origemAcao === 'GRUPO_FORNECEDOR';
    },
    exibeRadioQtdNds() {
      return !!this.formulario.fornecedorPagador && !!this.formulario.fornecedorPagador.id
        && this.exibeFornecedorPagador && (this.isCalculoBonificacaoSellin || this.isCalculoBonificacaoSellout
        || this.isCalculoBonificacaoFaturamentoFornecedor) && this.isAmbienteMagalu;
    },
    unicaOrigemAcao() {
      return this.origemAcaoConfiguracao.length === 1;
    },
    tiposPagamentoPermitidos() {
      const { pagamentos } = this.configuracao;
      return this.listaTiposPagamento
        .filter((p) => pagamentos.indexOf(p.id) >= 0)
        .map((p) => ({ nome: this.$tc(`tipos_pagamentos.${p.codTipo}`, 1), valor: p.codTipo }));
    },
    isCalculoBonificacaoValorFixo() {
      return this.acao.calculoBonificacao && this.acao.calculoBonificacao === 'VALOR_FIXO';
    },
    isCalculoBonificacaoSellout() {
      return this.acao.calculoBonificacao && this.acao.calculoBonificacao === 'SELLOUT';
    },
    isCalculoBonificacaoSellin() {
      return this.acao.calculoBonificacao && this.acao.calculoBonificacao === 'SELLIN';
    },
    isCalculoBonificacaoFaturamentoFornecedor() {
      return this.acao.calculoBonificacao && this.acao.calculoBonificacao === 'FATURAMENTO_FORNECEDOR';
    },
    desabilitaFornecedorPagador() {
      const { grupoFornecedor } = this.acao;
      if (this.fornecedoresPagadores.length === 1) {
        return true;
      }

      if (this.isAmbienteEpoca) {
        return false;
      }

      if (this.origemAcao === 'FORNECEDOR' || !grupoFornecedor || !grupoFornecedor.id) {
        return true;
      }
      return false;
    },
    exibeFornecedorPagador() {
      return this.configuracao.habilitaFornecedorPagador;
    },
    fornecedorPagadorObrigatorio() {
      return this.configuracao.fornecedorPagadorObrigatorio;
    },
    labelFornecedorPagador() {
      if (this.configuracao.fornecedorPagadorObrigatorio) {
        return `${this.$tc('label.cnpj_nota_debito', 1)} *`;
      }
      return this.$tc('label.cnpj_nota_debito', 1);
    },
    labelGrupoFornecedor() {
      if (this.isAmbienteEpoca && this.isCalculoBonificacaoValorFixo) {
        return this.$tc('label.divisao_fornecedor', 1);
      }
      return this.isCalculoBonificacaoValorFixo ? `${this.$tc('label.grupo_fornecedor', 1)} *` : `${this.$tc('label.base_apuracao', 1)} *`;
    },
    exibeIconeInformacao() {
      if (this.isAmbienteEpoca && this.isCalculoBonificacaoValorFixo) {
        return true;
      }
      return !this.isCalculoBonificacaoValorFixo;
    },
    exibeCnpjNotaDebito() {
      if (this.isAmbienteEpoca && this.isCalculoBonificacaoValorFixo) {
        return true;
      }
      return !this.isCalculoBonificacaoValorFixo;
    },
    isTipoPagamentoAvisoCredito() {
      return this.formulario.tipoPagamento === 'AVISO_CREDITO';
    },
    isAcaoEmCadastro() {
      return this.acao.status === 'EM_CADASTRO';
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    filtrarFornecedores(item, queryText) {
      return item.codNomeCnpj.toLowerCase().indexOf(queryText.toLowerCase()) > -1
        || item.desCnpj.replace(/[./-]/g, '').indexOf(queryText) > -1;
    },
    buscaGruposFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
      };
      this.planejamentoAcaoResource.buscarGruposFornecedores(parametros)
        .then((res) => {
          this.gruposFornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscaFornecedores(autocomplete) {
      const parametros = {
        autocomplete,
        pagador: false,
      };

      if (this.exibeFornecedorPagador && !this.isCalculoBonificacaoValorFixo) {
        parametros.fornecedorPagadorHabilitado = true;
      }

      this.planejamentoAcaoResource.buscarFornecedores(parametros)
        .then((res) => {
          this.fornecedores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarTiposPagamento() {
      return this.tipoBeneficioResources
        .listarAtivos()
        .then((response) => {
          this.listaTiposPagamento = response.data;
          setTimeout(this.definirTipoPagamento, 1E2);
        });
    },
    definirTipoPagamento() {
      if (this.tiposPagamentoPermitidos.length === 1) {
        this.formulario.tipoPagamento = this.tiposPagamentoPermitidos[0].valor;
      }
    },
    limparOrigem() {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.formulario;
      if (grupoFornecedor && grupoFornecedor.id) {
        this.formulario.grupoFornecedor = {};
      }
      if (fornecedor && fornecedor.id) {
        this.formulario.fornecedor = {};
      }
      if (fornecedorPagador && fornecedorPagador.id) {
        this.formulario.fornecedorPagador = {};
        this.formulario.qtdNds = 'UNICA';
      }
    },
    trataOrigemAcao() {
      if (this.isCalculoBonificacaoValorFixo) {
        this.origemAcao = 'FORNECEDOR';
      }
      if (this.unicaOrigemAcao) {
        this.origemAcao = this.origemAcaoConfiguracao[0].valor;
      }
    },
    valida() {
      return this.$refs.formOrigemAcao.validate();
    },
    getObjetoFormatado() {
      if (!this.isTipoPagamentoAvisoCredito) {
        this.formulario.numeroPedido = null;
      }
      const objetoFormatado = { ...this.formulario };
      this.formataOrigemAcao(objetoFormatado);
      return objetoFormatado;
    },
    formataOrigemAcao(objetoFormatado) {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.formulario;
      if (this.exibeOrigemGrupoFornecedor && grupoFornecedor) {
        objetoFormatado.idGrupoFornecedor = grupoFornecedor.id;
        if (this.exibeFornecedorPagador) {
          objetoFormatado.idFornecedorPagador = fornecedorPagador ? fornecedorPagador.id : null;
        }
      } else if (fornecedor) {
        objetoFormatado.idFornecedor = fornecedor.id;
      }
    },
    montaObjetoAcao() {
      this.formulario = {
        prazoVencimentoAcao: this.acao.prazo_pag_avulsa,
        grupoFornecedor: this.acao.grupoFornecedor,
        fornecedor: this.acao.fornecedor,
        fornecedorPagador: this.acao.fornecedorPagador,
        tipoPagamento: this.acao.tipoPagamento,
        indPossuiPassoFornecedor: this.acao.indPossuiPassoFornecedor,
        qtdNds: this.acao.qtdNds,
        numeroPedido: this.acao.numeroPedido,
      };
      if (this.isAmbienteEpoca) {
        this.grupoFornecedorAlterado(this.acao.grupoFornecedor);
      }
      this.setAcaoEdicao();
    },
    montaObjetoNovaAcao() {
      if (this.isCalculoBonificacaoValorFixo) {
        this.formulario.indPossuiPassoFornecedor = true;
      }
    },
    setAcaoEdicao() {
      const { fornecedor, grupoFornecedor, fornecedorPagador } = this.formulario;
      if (grupoFornecedor) {
        this.origemAcao = 'GRUPO_FORNECEDOR';
        this.gruposFornecedores = [grupoFornecedor];
      } else {
        this.origemAcao = 'FORNECEDOR';
        this.fornecedores = [fornecedor];
      }
      this.fornecedoresPagadores = fornecedorPagador ? [fornecedorPagador] : [];
    },
    excluirListaProduto() {
      delete this.acao.fornecedor;
      delete this.acao.grupoFornecedor;
      this.fornecedorPreSelecionado = null;
      this.grupoFornecedorPreSelecionado = null;
      this.$emit('origemAcao_apagarTodosProdutos');
    },
    retornarOrigem() {
      if (this.acao.idContratoVinculadoAntigo) {
        this.acao.idContratoVinculado = this.acao.idContratoVinculadoAntigo;
        this.acao.justificativaVinculoContrato = this.acao.justificativaVinculoContratoAntigo;
        this.$emit('origemAcao_buscarContratoVinculado');
      }
      let { fornecedor, grupoFornecedor } = this.acao;
      if (this.isRetornarOrigemFornecedor()) {
        fornecedor = this.fornecedorAnterior;
        this.fornecedorPreSelecionado = fornecedor;
      }
      if (this.isRetornarOrigemGrupo()) {
        grupoFornecedor = this.grupoFornecedorAnterior;
        this.grupoFornecedorPreSelecionado = grupoFornecedor;
      }
      this.formulario = {
        ...this.formulario,
        grupoFornecedor,
        fornecedor,
      };
      this.setAcaoEdicao();
    },
    isRetornarOrigemFornecedor() {
      return (this.fornecedorAnterior && ((this.listaProdutoReferencia.idFornecedor
          && this.fornecedorAnterior.id === this.listaProdutoReferencia.idFornecedor)
        || (this.listaProdutoReferencia.idGrupoFornecedor
          && this.fornecedorAnterior.idGrupoFornecedor === this.listaProdutoReferencia.idGrupoFornecedor)))
        || (this.grupoFornecedorPreSelecionado
          && !this.grupoFornecedorPreSelecionado.idsFornecedor.includes(this.listaProdutoReferencia.idFornecedor));
    },
    isRetornarOrigemGrupo() {
      return this.grupoFornecedorAnterior && this.listaProdutoReferencia.idGrupoFornecedor
        && this.listaProdutoReferencia.idGrupoFornecedor === this.grupoFornecedorAnterior.id
        && !this.grupoFornecedorPreSelecionado.idsFornecedor.includes(this.listaProdutoReferencia.idFornecedor);
    },
    grupoFornecedorAlterado(grupoFornecedor) {
      if (!this.exibeFornecedorPagador) {
        return;
      }
      this.acao.grupoFornecedor = grupoFornecedor;
      this.buscaFornecedoresPagadores().then(() => {
        if (this.isAmbienteEpoca
          && this.fornecedoresPagadores && this.acao.fornecedorPagador) {
          const fornecedorPagador = this.fornecedoresPagadores.find((el) => el.id === this.acao.fornecedorPagador.id);
          if (fornecedorPagador) {
            this.formulario.fornecedorPagador = fornecedorPagador;
            this.acao.fornecedorPagador = fornecedorPagador;
            this.panelKey += 1;
            return;
          }
        }
        if (this.fornecedoresPagadores.length === 1) {
          const unico = this.fornecedoresPagadores[0];
          this.formulario.fornecedorPagador = unico;
          this.acao.fornecedorPagador = unico;
          this.panelKey += 1;
        } else {
          this.formulario.fornecedorPagador = {};
        }
        this.formulario.qtdNds = 'UNICA';
      });
    },
    buscaFornecedoresPagadores() {
      const parametros = {
        idGrupoFornecedor: this.formulario.grupoFornecedor ? this.formulario.grupoFornecedor.id : 0,
      };
      if (this.isAmbienteEpoca) {
        parametros.pagador = false;
        parametros.apenasMatriz = true;
      } else {
        parametros.pagador = true;
      }

      if (!this.formulario.grupoFornecedor || !this.formulario.grupoFornecedor.id) {
        parametros.idFornecedor = this.formulario.fornecedor ? this.formulario.fornecedor.id : 0;
      }
      return this.planejamentoAcaoResource.buscarFornecedoresPagadores(parametros)
        .then((res) => {
          this.fornecedoresPagadores = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    fornecedorAlterado(fornecedor) {
      this.acao.fornecedor = fornecedor;

      if (!this.exibeFornecedorPagador) {
        this.formulario.fornecedorPagador = fornecedor ? { ...fornecedor } : null;
        this.fornecedoresPagadores = this.formulario.fornecedorPagador ? [
          this.formulario.fornecedorPagador,
        ] : [];
        return;
      }

      this.buscaFornecedoresPagadores().then(() => {
        if (this.fornecedoresPagadores.length === 1) {
          const unico = this.fornecedoresPagadores[0];
          this.formulario.fornecedorPagador = unico;
          this.acao.fornecedorPagador = unico;
        } else {
          this.formulario.fornecedorPagador = {};
        }
        this.formulario.qtdNds = 'UNICA';
      });
    },
    validarVinculoDeContrato(idFornecedor) {
      this.$emit('origemAcao_validarVinculoDeContrato', idFornecedor);
    },
    verificaAlteracaoIdContratoVinculado() {
      if (this.acao.idContratoVinculado) {
        this.acao.idContratoVinculadoAntigo = this.acao.idContratoVinculado;
        this.acao.justificativaVinculoContratoAntigo = this.acao.justificativaVinculoContrato;
        this.$refs.confirmAlteracaoConfiguracaoVinculo.open();
        return true;
      }
      return false;
    },
    retornarConfiguracaoVinculo() {
      if (this.acao.idContratoVinculadoAntigo) {
        this.acao.idContratoVinculado = this.acao.idContratoVinculadoAntigo;
        this.acao.justificativaVinculoContrato = this.acao.justificativaVinculoContratoAntigo;
        this.$emit('origemAcao_buscarContratoVinculado');
      }
      if (this.acao.dataInicioAnterior) {
        this.acao.dtaInicio = this.acao.dataInicioAnterior;
      }
      if (this.acao.dataFimAnterior) {
        this.acao.dtaFim = this.acao.dataFimAnterior;
      }
    },
    alterarConfiguracaoVinculo() {
      this.$emit('origemAcao_alterarConfiguracaoVinculo');
    },
  },
  mounted() {
    this.buscarTiposPagamento()
      .then(() => {
        this.trataOrigemAcao();
        if (!this.novaAcao) {
          this.montaObjetoAcao();
        } else {
          this.montaObjetoNovaAcao();
        }
        setTimeout(this.carregouSecao = true);
      });
  },
};
</script>
<style>
  .plan_acao_prazo_vencimento .v-messages__message, .plan_acao_qtd_nds .v-messages__message {
    color: #FF4500;
  }
</style>
