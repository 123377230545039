<style>
.PlanejamentoAcaoFormCampos__Card--treslinhas {
  min-height: 268px;
  height: 100%;
}
.PlanejamentoAcaoFormCampos .title-float {
  font-weight: 400;
  font-size: 20px;
}
</style>
<template>
<div class="PlanejamentoAcaoFormCampos">
  <v-form
    ref="formGeral"
    lazy-validation
    autocomplete="off">
    <v-container fluid grid-list-md class="px-0">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="pb-0">
              <div class="card-title" slot="titulo-acao">
                {{ $t('label.criacao_acao')}} :: {{ tituloTipoAcao }}
              </div>
              <v-spacer></v-spacer>
              <div class="card-title" v-if="!novaAcao && !isCopiaAcao && !exibeNumNotaDebito && !exibeNotaFiscal">
                {{ $t('label.id_acao')}}: {{ acao.id }}
              </div>
              <div class="card-title" v-if="!novaAcao && !isCopiaAcao && exibeNumNotaDebito && !exibeNotaFiscal">
                {{ $t('label.numero_nota_debito')}}: {{ acao.num_nota_debito }}
              </div>
              <div class="card-title" v-if="!novaAcao && !isCopiaAcao && exibeNotaFiscal">
                {{ `${$t('label.id_acao')}: ${acao.id} | ${$t('label.nota_fiscal')}: ${acao.numeroNotaFiscal}` }}
              </div>
              <v-spacer></v-spacer>
              <div class="card-title" >
                {{ $t('label.status')}}: <span :style="`color: ${corStatus}`">{{ $tc(`status_entidade.${acao.status.toLowerCase()}`, 1) }}</span>
                <v-tooltip left v-if="mostrarAcompanhamento(acao)">
                  <template v-slot:activator="{ on }">
                    <v-btn text icon
                          v-on="on"
                          @click.native="abrirAcompanhamento(acao)">
                      <v-icon >assignment_turned_in</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('label.acompanhamento_fluxo_aprovacao') }}</span>
                </v-tooltip>
              </div>
            </v-card-title>
            <v-subheader slot="subtitulo-acao">
              {{subtituloTipoAcao}}
            </v-subheader>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      id="descricao"
                      v-model="acao.descricao"
                      maxlength="350"
                      :counter="350"
                      :rules="[rules.required]"
                      :disabled="somenteLeitura || configuracao.indDescricaoAutomatica"
                      :label="`${$tc('label.descricao', 1)} *`"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      autocomplete="off"
                      id="planejamento-autocomplete-divisao"
                      class="custom-autocomplete"
                      v-model="acao.divisao"
                      return-object
                      :rules="rules.requiredFunction('divisao', 'nome')"
                      :items="divisoesFiltradas"
                      :label="`${$tc('label.divisao', 1)} *`"
                      :no-data-text="$tc('message.nenhum_registro', 1)"
                      item-text="nome"
                      item-value="id"
                      required
                      :disabled="somenteLeitura || desabilitaDivisao"
                      :clearable="true"
                      @input="divisaoAlterada"
                      @click:append="() => triggerSelecao('planejamento-autocomplete-divisao')"
                      @click.native="(i) => buscaAutocomplete(i, buscarDivisoes)">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete
                      autocomplete="off"
                      id="planejamento-autocomplete-unidadenegocio"
                      class="custom-autocomplete"
                      v-model="acao.unidadeNegocio"
                      return-object
                      :items="unidadesNegocioDivisao"
                      :rules="rules.requiredFunction('unidadeNegocio', 'nomExtensao')"
                      :label="`${$tc('label.unidade_negocio', 1)} *`"
                      :no-data-text="$tc('message.nenhum_registro', 1)"
                      item-text="nomExtensao"
                      item-value="id"
                      required
                      :disabled="somenteLeitura || desabilitaUnidadeNegocio"
                      :clearable="true"
                      @click:append="() => triggerSelecao('planejamento-autocomplete-unidadenegocio')"
                      @click.native="(i) => buscaAutocomplete(i, buscarUnidadesNegocioDivisao)">
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" v-if="isCalculoNDMaximoCampanha" :key="componentKey">
                    <input-money
                      id="plan_acao_teto_acao"
                      v-model="acao.tetoAcao"
                      :label="`${$tc('label.teto_acao', 1)} *`"
                      class="v-text-field"
                      :max="9999999999"
                      :rules="[rules.required, v => rules.valorMinimo(v, 0.000000001)]"
                      :disabled="somenteLeitura || desabilitaTetoAcao"
                      @change="atualizarValorTetoAcaoRodape()"
                      :persistent-hint="true"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <metadados-campo-dinamico-data
                      :metadados="definicaoDtaInicio"
                      ref="dtaInicio"
                      v-model="acao.dtaInicio"
                      :somente-leitura="somenteLeitura"
                      :objeto-container="acao"
                      :tipo-selecao="isAcaoVigenciaTrimestral ? 'month' : 'date'"
                      :is-vigencia-trimestral="isAcaoVigenciaTrimestral"
                      :is-data-inicio="true"
                      @MetadadosCampoDinamico_valorAlterado="dataInicioAlterada"
                      :aplicar-restricao-data-atual="isModalidadeValorFixo"
                      @focus="valorDataInicioAterior(acao.dtaInicio)"/>
                  </v-col>
                  <v-col cols="12" md="3">
                    <metadados-campo-dinamico-data
                      :metadados="definicaoDtaFim"
                      v-model="acao.dtaFim"
                      :somente-leitura="somenteLeitura"
                      :objeto-container="acao"
                      :tipo-selecao="isAcaoVigenciaTrimestral ? 'month' : 'date'"
                      :is-vigencia-trimestral="isAcaoVigenciaTrimestral"
                      :is-data-fim="true"
                      @MetadadosCampoDinamico_valorAlterado="dataFimAlterada"
                      :aplicar-restricao-data-atual="isModalidadeValorFixo"
                      @focus="valorDataFimAterior(acao.dtaFim)"/>
                  </v-col>
                  <v-col v-if="indExcedeRetroatividade" cols="12" md="12" class="pa-0">
                    <label
                      class="text-subtitle-1 font-weight-bold"
                      style="color:#F57C00;"
                      id="aviso-excede-retroatividade"
                    >
                      {{ $t('message.aviso_excede_retroatividade') }}
                      <span
                        v-if="justfExcedeRetroatividade"
                        @click="abrirModalJustfExcedeRetroatividadeEdicao"
                      >
                        <v-tooltip right>
                          {{ $t('label.justificativa_excede_retroatividade') }}
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on">
                              description
                            </v-icon>
                          </template>
                        </v-tooltip>
                      </span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                    id="cod_fornecedor"
                    v-model="acao.codFornecedorAcao"
                    maxlength="50"
                    :disabled="somenteLeitura"
                    :label="`${$tc('label.cod_fornecedor_acao', 1)}`">
                    <template v-slot:append-outer>
                      <v-tooltip
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">info</v-icon>
                        </template>
                        {{$tc('label.codigo_enviado_fornecedor_campanha', 1)}}
                      </v-tooltip>
                    </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" v-if="exibeDataEmissao">
                    <v-row>
                      <v-col cols="10">
                        <v-text-field
                          :return-masked-value="true"
                          prepend-icon="event"
                          v-mask="'##-##-####'"
                          :disabled="true"
                          v-model="acao.emissao.dataEmissao"
                          :label="$t('label.data_emissao')">
                        </v-text-field>
                      </v-col>
                      <v-col cols="2" class="mt-5 ml-0">
                        <v-tooltip
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              v-bind:style="{color: getCollorIcon(acao)}">lens
                            </v-icon>
                          </template>
                          {{acao.emissao.regraDataEmissao}}
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="3" v-else-if="acao.id && acao.emissao && !acao.emissao.dataEmissao && acao.status === 'APROVADO'">
                    <span v-html="`${$t('message.varias_apuracoes')}`" />
                    <a @click="abrirModalGerarNd(acao)" class="text-decoration-underline blue--text ml-1">
                      {{ $t('label.link') }}
                    </a>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>

  <v-container fluid grid-list-md>
    <v-row v-if="carregouSecao">
      <v-col cols="12" :md="!exibeBaseCalculo && !exibeCamposAds ? !exibeCampoRateioEntreTiposDeUso ?  6 : 4 : 4">
        <planejamento-acao-verba-variavel-form-origem-acao
          ref="secaoOrigemAcao"
          :acao="acao"
          :acao-edicao="acaoEdicao"
          :tipo-acao="tipoAcao"
          :nova-acao="novaAcao"
          :is-copia-acao="isCopiaAcao"
          :configuracao="configuracao"
          :somente-leitura="somenteLeitura"
          :get-lista-produtos="getListaProdutos"
          @origemAcao_apagarTodosProdutos="apagarTodosProdutos"
          @origemAcao_validarVinculoDeContrato="validarVinculoDeContrato"
          @origemAcao_buscarContratoVinculado="buscarContratoVinculado"
          @origemAcao_alterarConfiguracaoVinculo="alterarConfiguracaoVinculo"
        />
      </v-col>
      <v-col cols="12" :md="!exibeBaseCalculo && !exibeCamposAds ? !exibeCampoRateioEntreTiposDeUso ?  6 : 2 : !exibeCampoRateioEntreTiposDeUso ?  4 : 2">
        <planejamento-acao-verba-variavel-form-configuracao
          ref="secaoConfiguracao"
          :acao="acao"
          :acao-edicao="acaoEdicao"
          :tipo-acao="tipoAcao"
          :nova-acao="novaAcao"
          :configuracao="configuracao"
          :somente-leitura="somenteLeitura"
        />
      </v-col>
      <v-col cols="12" :md="6" v-if="exibeCampoRateioEntreTiposDeUso">
        <planejamento-acao-verba-variavel-form-rateio-contabil
          ref="secaoRateioContabil"
          :acao="acao"
          :acao-edicao="acaoEdicao"
          :tipo-acao="tipoAcao"
          :nova-acao="novaAcao"
          :configuracao="configuracao"
          :valor-total-acao-rateio="valorTotalAcaoRateio"
          :limpa-lista-rateio="indLimparListaRateio"
          @PlanejamentoAcaoVerbaVariavelFormCampos__countListaRateioTipoUso="atualizaCountListaRateioTipoUso"
          @PlanejamentoAcaoVerbaVariavelFormCampos__isValorPendenteZerado="verificacaoParaSolicitarAprovacaoRateio"
          :somente-leitura="somenteLeitura"
        />
      </v-col>
      <v-col cols="12" md="4">
        <planejamento-acao-verba-variavel-form-apuracao
          ref="secaoApuracao"
          v-if="exibeBaseCalculo"
          :acao="acao"
          :acao-edicao="acaoEdicao"
          :tipo-acao="tipoAcao"
          :nova-acao="novaAcao"
          :configuracao="configuracao"
          :somente-leitura="somenteLeitura"
        />
        <planejamento-acao-verba-variavel-form-campos-ads
          ref="secaoCamposAds"
          v-else-if="exibeCamposAds"
          :acao="acao"
          :acao-edicao="acaoEdicao"
          :tipo-acao="tipoAcao"
          :nova-acao="novaAcao"
          :configuracao="configuracao"
          :somente-leitura="somenteLeitura"
        />
      </v-col>
    </v-row>

    <v-row v-if="carregouSecao">
      <v-col cols="12">
        <planejamento-acao-verba-variavel-form-produtos
          ref="secaoProduto"
          :acao="acao"
          :acao-edicao="acaoEdicao"
          :tipo-acao="tipoAcao"
          :nova-acao="novaAcao"
          :configuracao="configuracao"
          :somente-leitura="somenteLeitura"
          :get-origem-acao="getOrigemAcao"
          :count-lista-rateio-tipo-uso="countListaRateio"
          @PlanejamentoAcaoVerbaVariavelFormCampos__atualizarValorTeToAcao="atualizarValorTetoAcao"
          @PlanejamentoAcaoVerbaVariavelFormCampos__atualizarValorTeToAcaoParaRateioContabil="atualizarValorTetoAcaoParaRateioContabil"
          @PlanejamentoAcaoVerbaVariavelFormCampos__limparListaRateioTipoUso="limparListaRateioTipoUso"
          @PlanejamentoAcaoVerbaVariavelFormCampos__salvarMd5ArquivoProduto="salvarMd5ArquivoProduto"
        />
      </v-col>
    </v-row>

    <div v-if="carregouSecao">
      <div class="mt-3 mb-2 title-float accent--text">
        {{$tc('label.anexo', 2)}}
      </div>
      <v-card>
        <v-container fluid grid-list-md>
          <planejamento-acao-verba-variavel-form-anexo-arquivo
            ref="formAnexoArquivo"
            :id="novaAcao ? 0 : acaoEdicao.id"
            @FormAnexoArquivo__qtde="atualizaQtdeArquivosAnexados"
            :acao="acaoEdicao"
            :is-copia-acao="isCopiaAcao"
            :habilita-anexo-fornecedor="habilitaAnexoFornecedor"
            :configuracao="configuracao"
            :nova-acao="novaAcao"
            :get-origem-acao="getOrigemAcao">
          </planejamento-acao-verba-variavel-form-anexo-arquivo>
        </v-container>
      </v-card>
    </div>
  </v-container>
  <acompanhamento
  ref="modalAcompanhamento"
  :dados-modal-acompanhamento="dadosModalAcompanhamento"
  :fluxo="statusPassos"
  :entidade="'ACAO'">
  </acompanhamento>

  <apuracao-acao-lista-modal-gerar-nd
    ref="modalGerarNd"
    v-if="acaoModal"
    v-model="acaoModal"
    :abrir-modal="abrirModalND"
    :ocultaResumo="true"
    @ApuracaoAcaoListaModalGerarNd__fechar="fecharModalND">
  </apuracao-acao-lista-modal-gerar-nd>

  <planejamento-acao-justificativa
    ref="modalJustificativa"
    :obrigatorio="justificativaObrigatoria"
    :salvarJustificativa="actionJustificativa"
    :observacao="justfExcedeRetroatividade"
    :somenteLeitura="somenteLeitura"
    :titulo-modal="tituloModalJustificativa"
    @PlanejamentoAcaoJustificativa__Cancelar="cancelarJustificativa">
  </planejamento-acao-justificativa>
</div>

</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import InputMoney from '@/shared-components/inputs/InputMoney';
import ApuracaoAcaoListaModalGerarNd from '@/spa/apuracao-acao/ApuracaoAcaoListaModalGerarNd';
import MetadadosCampoDinamicoData from '@/shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import Acompanhamento from '@/shared-components/workflow/Acompanhamento';
import PlanejamentoAcaoVerbaVariavelFormCamposDefinicao from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormCamposDefinicao';
import PlanejamentoAcaoVerbaVariavelFormOrigemAcao from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormOrigemAcao';
import PlanejamentoAcaoVerbaVariavelFormConfiguracao from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormConfiguracao';
import PlanejamentoAcaoVerbaVariavelFormRateioContabil from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormRateioContabil';
import PlanejamentoAcaoVerbaVariavelFormApuracao from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormApuracao';
import PlanejamentoAcaoVerbaVariavelFormCamposAds from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormCamposAds';
import PlanejamentoAcaoVerbaVariavelFormProdutos from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormProdutos';
import PlanejamentoAcaoVerbaVariavelFormAnexoArquivo from '@/spa/planejamento-acao/form-verba-variavel/PlanejamentoAcaoVerbaVariavelFormAnexoArquivo';

import { buscarPerfilPassoAprovador } from '@/common/resources/workflow-acao';
import PlanejamentoAcaoJustificativa from '../PlanejamentoAcaoJustificativa';

export default {
  components: {
    InputMoney,
    MetadadosCampoDinamicoData,
    PlanejamentoAcaoVerbaVariavelFormOrigemAcao,
    PlanejamentoAcaoVerbaVariavelFormConfiguracao,
    PlanejamentoAcaoVerbaVariavelFormRateioContabil,
    PlanejamentoAcaoVerbaVariavelFormApuracao,
    PlanejamentoAcaoVerbaVariavelFormCamposAds,
    PlanejamentoAcaoVerbaVariavelFormProdutos,
    PlanejamentoAcaoVerbaVariavelFormAnexoArquivo,
    Acompanhamento,
    ApuracaoAcaoListaModalGerarNd,
    PlanejamentoAcaoJustificativa,
  },
  mixins: [
    PlanejamentoAcaoVerbaVariavelFormCamposDefinicao,
  ],
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,
    isCopiaAcao: Boolean,
    copiaAcao: Object,
    tipoAcao: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      configSistemaResource: this.$api.configuracaoSistema(this.$resource),
      qtdeArquivosAnexados: 0,
      ordenacaoFormulario: null,
      carregouSecao: false,
      permiteAlterarDivisao: true,
      componentKey: 0,
      statusPassos: {},
      dadosModalAcompanhamento: {},
      valorTotalAcaoRateio: 0,
      countListaRateio: 0,
      indLimparListaRateio: false,
      podeSolicitarAprovacaoRateio: false,
      listaMd5ArquivoProduto: [],
      acaoModal: null,
      abrirModalND: false,
      indExcedeRetroatividade: false,
      dataMaximaCalcRetroativo: '',
      justificativaObrigatoria: true,
      tituloModalJustificativa: this.$t('label.justificativa_excede_retroatividade'),
      modalExcedeRetroatividadeEdicao: false,
      modalExcedeRetroatividadeSalvar: false,
      justfExcedeRetroatividade: null,
      isSolicitandoAprovacao: false,

      colorPalette: [
        '#1abc9c', // Verde
        '#e74c3c', // Vermelho
        '#e67e22', // Laranja
        '#f1c40f', // Amarelo
        '#000000', // Preto
      ],
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'chaveEmpresa',
    ]),
    isAmbienteMagalu() {
      return this.chaveEmpresa === 'MAGALU';
    },
    isAmbienteEpoca() {
      return this.chaveEmpresa === 'EPOCA';
    },
    isAmbienteKabum() {
      return this.chaveEmpresa === 'KABUM';
    },
    isUsuarioFornecedor() {
      return this.usuarioLogado.tipo === 'FORNECEDOR';
    },
    tituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.nome : '';
    },
    subtituloTipoAcao() {
      return this.tipoAcao != null ? this.tipoAcao.descricao : '';
    },
    exibeNumNotaDebito() {
      return this.acao.num_nota_debito && this.isModalidadeValorFixo;
    },
    exibeNotaFiscal() {
      return this.acao.numeroNotaFiscal && this.isModalidadeNotaFiscal;
    },
    isAcaoVigenciaTrimestral() {
      return this.configuracao.indVigenciaTrimestral;
    },
    corStatus() {
      let colorIndex = 4;
      switch (this.acao.status) {
        case 'APROVADO':
          colorIndex = 0;
          break;
        case 'EM_ANALISE':
          colorIndex = 3;
          break;
        case 'CANCELADO':
          colorIndex = 3;
          break;
        case 'AGUARDANDO_APROVACAO':
          colorIndex = 2;
          break;
        case 'REPROVADO':
          colorIndex = 1;
          break;
        default:
          colorIndex = 4;
      }
      return this.colorPalette[colorIndex];
    },
    isModalidadeValorFixo() {
      return this.acao.calculoBonificacao
        && this.acao.calculoBonificacao === 'VALOR_FIXO';
    },
    isModalidadeNotaFiscal() {
      return this.acao.calculoBonificacao
        && this.acao.calculoBonificacao === 'NOTA_FISCAL';
    },
    isCalculoApuracaoSelloutPercentual() {
      return this.acao.calculoApuracao
          && this.acao.calculoApuracao === 'SELLOUT_PERCENTUAL';
    },
    isCalculoApuracaoQuantidade() {
      return this.acao.calculoApuracao
        && this.acao.calculoApuracao === 'QUANTIDADE';
    },
    isTipoCalculoPercentual() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL';
    },
    isModalidadeSellout() {
      return this.acao.calculoBonificacao
          && this.acao.calculoBonificacao === 'SELLOUT';
    },
    isTipoCalculoFixo() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'FIXO';
    },
    isTipoCalculoValorPorPeca() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'VALOR_POR_PECA';
    },
    habilitaAnexoFornecedor() {
      return this.novaAcao || (this.acao && this.acao.status === 'EM_CADASTRO');
    },
    exibeCamposAds() {
      return this.configuracao.indInformaCamposAds
        && this.isModalidadeValorFixo
        && !this.isUsuarioFornecedor;
    },
    exibeCampoRateioEntreTiposDeUso() {
      return this.isAmbienteKabum
        && this.configuracao.indHabilitarRateioContabil
        && this.isModalidadeValorFixo
        && !this.isUsuarioFornecedor;
    },
    exibeBaseCalculo() {
      return (!this.isModalidadeValorFixo && !this.isModalidadeNotaFiscal && !this.isTipoCalculoValorPorPeca && !this.isCalculoApuracaoQuantidade)
      || (this.isModalidadeSellout && this.isTipoCalculoPercentual && this.isCalculoApuracaoQuantidade)
      || (this.isModalidadeSellout && (this.isTipoCalculoPercentual || this.isTipoCalculoValorPorPeca));
    },
    exibeRateioContabil() {
      return (this.configuracao.indHabilitarRateioContabil && this.isModalidadeValorFixo);
    },
    desabilitaTetoAcao() {
      return !!(this.acao.possuiGatilhoGerarRecebimento && !this.isTipoCalculoPercentual);
    },
    isCalculoNDMaximoCampanha() {
      return this.acao.tipoCalculoValorMaximoND && this.acao.tipoCalculoValorMaximoND === 'CALCULO_CAMPANHA_ND_MAXIMO';
    },
    exibeDataEmissao() {
      return this.isAmbienteMagalu && this.acao.id && this.acao.emissao
        && this.acao.emissao.dataEmissao;
    },
  },
  methods: {
    salvarMd5ArquivoProduto(md5) {
      this.listaMd5ArquivoProduto.push(md5);
    },
    valida(isSolicitandoAprovacao = false) {
      let formsValidos = this.$refs.formGeral.validate();
      formsValidos = this.$refs.secaoOrigemAcao.valida() && formsValidos;
      if (this.exibeBaseCalculo) {
        formsValidos = this.$refs.secaoApuracao.valida() && formsValidos;
      }
      if (this.exibeCamposAds) {
        formsValidos = this.$refs.secaoCamposAds.valida() && formsValidos;
      }

      if (!formsValidos) {
        this.$toast(this.$t('errors.campos_obrigatorios.nao_informados'));
      }

      if (!this.$refs.secaoProduto.valida()) {
        formsValidos = formsValidos && this.$refs.secaoProduto.valida();
        this.$toast(this.$t('errors.lista.linha_produto_vazia'));
      }
      if (!this.validacaoCustomizada(isSolicitandoAprovacao)) {
        return false;
      }

      if (!this.validacaoJustfExcedeRetroatividade(isSolicitandoAprovacao)) {
        return false;
      }

      return formsValidos
        && this.validarDatas();
    },
    validacaoJustfExcedeRetroatividade(isSolicitandoAprovacao) {
      if (this.indExcedeRetroatividade && this.justfExcedeRetroatividade) {
        this.acao.justificativaCampanhaExcedeRetroatividade = this.justfExcedeRetroatividade;
      }
      if (!this.indExcedeRetroatividade && this.justfExcedeRetroatividade) {
        this.acao.justificativaCampanhaExcedeRetroatividade = null;
      }
      if (this.indExcedeRetroatividade && !this.acao.justificativaCampanhaExcedeRetroatividade) {
        this.modalExcedeRetroatividadeSalvar = true;
        this.isSolicitandoAprovacao = isSolicitandoAprovacao;
        this.abrirModalJustfExcedeRetroatividade();
        return false;
      }
      return true;
    },
    validacaoCustomizada(isSolicitandoAprovacao) {
      if (!isSolicitandoAprovacao) {
        return true;
      }
      if (this.configuracao.uploadObrigatorioArquivo && this.qtdeArquivosAnexados === 0) {
        this.$toast(this.$t('message.upload_arquivo_obrigatorio_solicitacao'));
        return false;
      }
      return true;
    },
    validarDatas() {
      const dataInicial = moment(this.acao.dtaInicio);
      const dataFinal = moment(this.acao.dtaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      return true;
    },
    atualizaQtdeArquivosAnexados(qtde = 0) {
      this.qtdeArquivosAnexados = qtde;
      this.$emit('PlanejamentoAcaoForm__AtualizaIndTemEvidencia', qtde > 0);
    },
    salvarNovoBudgetId() {
      return this.$refs.secaoRateioContabil.salvarNovoBudgetId();
    },
    getObjetoFormatado() {
      const { idConfiguracao } = this.configuracao;
      const {
        divisao,
        unidadeNegocio,
        possuiValorMaximoNd,
      } = this.acao;
      const dadosOrigemAcao = this.$refs.secaoOrigemAcao.getObjetoFormatado();

      let objetoFormatado = {
        ...this.acao,
        idAcaoTipo: this.tipoAcao.id,
        idConfiguracao,
        idDivisao: divisao.id,
        idUnidadeNegocio: unidadeNegocio ? unidadeNegocio.id : null,
        indValorMaximoND: possuiValorMaximoNd,
        ...dadosOrigemAcao,
      };

      if (possuiValorMaximoNd) {
        objetoFormatado = {
          ...objetoFormatado,
          tipoCalculoValorMaximoND: this.acao.tipoCalculoValorMaximoND,
          tetoAcao: this.acao.tetoAcao ? parseFloat(this.acao.tetoAcao, 10) : null,
          recebimentoEstimado: this.isCalculoNDMaximoCampanha ? null : this.acao.recebimentoEstimado,
          valorMaximoNd: this.isCalculoNDMaximoCampanha ? null : this.acao.valorMaximoNd,
        };
      }

      if (this.exibeBaseCalculo) {
        const dadosApuracaoAcao = this.$refs.secaoApuracao.getObjetoFormatado();
        objetoFormatado = {
          ...objetoFormatado,
          ...dadosApuracaoAcao,
        };
      } else {
        objetoFormatado.baseCalculo = 'VALOR_BRUTO';
        objetoFormatado.descontos = ['DEVOLUCOES'];
      }

      if (this.exibeCamposAds) {
        const dadosCamposAds = this.$refs.secaoCamposAds.getObjetoFormatado();
        objetoFormatado = {
          ...objetoFormatado,
          ...dadosCamposAds,
        };
      }

      const dadosLinhaProduto = this.$refs.secaoProduto.getObjetoFormatado();
      objetoFormatado.linhasProduto = dadosLinhaProduto;

      if (this.isAmbienteKabum && this.exibeCampoRateioEntreTiposDeUso && this.countListaRateio > 0) {
        const dadosRateio = this.$refs.secaoRateioContabil.getObjetoFormatado();
        objetoFormatado.linhasRateioContabil = dadosRateio.listaRateioMontada;
        objetoFormatado.valorTotal = dadosRateio.valorTotal;
        objetoFormatado.valorPendente = dadosRateio.valorPendente;
        objetoFormatado.valorAlocado = dadosRateio.valorAlocado;
      } else {
        objetoFormatado.linhasRateioContabil = [];
        objetoFormatado.valorTotal = null;
        objetoFormatado.valorPendente = null;
        objetoFormatado.valorAlocado = null;
      }

      if (this.acao.id) {
        objetoFormatado.id = this.acao.id;
      }

      if (this.listaMd5ArquivoProduto.length) {
        objetoFormatado.listaMd5ArquivoProduto = this.listaMd5ArquivoProduto;
      }

      this.formataVigenciaAberta(objetoFormatado);
      this.formataCodCampanha(objetoFormatado);
      return objetoFormatado;
    },
    formataVigenciaAberta(objetoFormatado) {
      if (this.isAcaoVigenciaTrimestral) {
        objetoFormatado.dtaInicio = moment(objetoFormatado.dtaInicio, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
        objetoFormatado.dtaFim = moment(objetoFormatado.dtaFim, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
      }
    },
    formataCodCampanha(objetoFormatado) {
      objetoFormatado.codCampanha = '';
      // Caso modalidade = VALOR_FIXO, o codigo sera apenas isso
      if (this.isModalidadeValorFixo) {
        objetoFormatado.codCampanha = this.acao.calculoBonificacao.toUpperCase();
      } else {
        // Caso nao, segue o padrao: TIPO DE CALCULO-MODALIDADE-BASE DE CALCULO
        if (this.isTipoCalculoFixo) {
          objetoFormatado.codCampanha = objetoFormatado.codCampanha
            .concat('FIXO');
        } else if (this.isTipoCalculoPercentual) {
          objetoFormatado.codCampanha = objetoFormatado.codCampanha
            .concat('%');
        } else if (this.isTipoCalculoValorPorPeca) {
          objetoFormatado.codCampanha = objetoFormatado.codCampanha
            .concat('VALOR_POR_PECA').concat('-').concat(this.acao.calculoBonificacao.toUpperCase());
        }

        if (!this.isTipoCalculoValorPorPeca) {
          objetoFormatado.codCampanha = objetoFormatado.codCampanha
            .concat('-').concat(this.acao.calculoBonificacao.toUpperCase());

          if (objetoFormatado.baseCalculo === 'VALOR_BRUTO' && !this.isModalidadeSellout) {
            objetoFormatado.codCampanha = objetoFormatado.codCampanha
              .concat('-').concat('BRUTO');
          } else if (objetoFormatado.baseCalculo !== 'VALOR_BRUTO') {
            objetoFormatado.codCampanha = objetoFormatado.codCampanha
              .concat('-').concat('NET');
          }
        }
      }
    },
    aplicaParametrizacoes() {
      this.metadadosParametrizado = this.configuracao.aplicaParametrizacao(this.getAcaoMetadado);
      this.configuracao
        .parametrizaCamposOpcionais(this.camposFormulario.padrao);
    },
    montarNovaAcao(routeParams) {
      const {
        idTipoAcao,
        calculoBonificacao,
        formaBonificacao,
        possuiValorMaximoNd,
        possuiGatilhoGerarRecebimento,
        calculoApuracao,
        tipoCalculoValorMaximoND,
      } = routeParams;
      this.acao = {
        ...this.acao,
        idAcaoTipo: idTipoAcao,
        calculoBonificacao,
        formaBonificacao,
        possuiValorMaximoNd,
        possuiGatilhoGerarRecebimento,
        tipoCalculoValorMaximoND,
      };
      if (calculoApuracao) {
        this.acao.calculoApuracao = calculoApuracao;
      }

      // modalidade valor fixo
      if (possuiValorMaximoNd && !tipoCalculoValorMaximoND) {
        this.acao.tipoCalculoValorMaximoND = 'CALCULO_PRODUTO_ND_MAXIMO';
      }
      this.carregouSecao = true;
    },
    montaObjetoAcao() {
      this.acao = {
        ...this.acaoEdicao,
        ...this.acaoEdicao.mapaExtensoes,
        ...this.acaoEdicao.mapaCamposDinamicos,
      };
      if (this.isCopiaAcao) {
        this.limpaCamposCopiaAcao(this.acao);
      }
      if (this.acao.calculoApuracao) {
        this.acao.possuiGatilhoGerarRecebimento = true;
      } else {
        this.acao.possuiGatilhoGerarRecebimento = false;
      }

      const possuiValorMaximo = this.acao.linhasProduto
        .filter((lp) => lp.valorMaximoNd && lp.valorMaximoNd > 0).length > 0;
        // mudar aqui quando normalizar a base
      if (possuiValorMaximo || this.acaoEdicao.possuiValorMaximoNd) {
        this.acao.possuiValorMaximoNd = true;
      } else {
        this.acao.possuiValorMaximoNd = false;
      }

      if (this.acaoEdicao.unidadeNegocio) {
        this.unidadesNegocioDivisao = [this.acaoEdicao.unidadeNegocio];
      }
      this.acao.codFornecedorAcao = this.acaoEdicao.codFornecedorAcao;
      this.divisoesFiltradas = [this.acaoEdicao.divisao];

      if (!this.acao.justificativaCampanhaExcedeRetroatividade) {
        this.acao.justificativaCampanhaExcedeRetroatividade = null;
      }

      this.justfExcedeRetroatividade = this.acao.justificativaCampanhaExcedeRetroatividade;

      this.carregouSecao = true;
    },
    limpaCamposCopiaAcao(acao) {
      if (acao.id) {
        acao.idCopia = acao.id;
        acao.id = null;
      }
      if (acao.idAcao) {
        acao.idAcao = null;
      }
      if (acao.unidadeNegocio) {
        acao.unidadeNegocio = {};
      }
      if (acao.descricao) {
        acao.descricao = '';
      }
      if (acao.dtaFim) {
        acao.dtaFim = '';
      }
      if (acao.dtaInicio) {
        acao.dtaInicio = '';
      }
      if (acao.mapaCamposDinamicos) {
        delete acao.mapaCamposDinamicos.num_nota_debito;
      }
    },
    preencherValoresAutomaticos() {
      if (this.configuracao.indDescricaoAutomatica) {
        this.acao.descricao = this.configuracao.descricaoAutomatica;
      }
      this.acao.justificativaCampanhaExcedeRetroatividade = null;
      if (this.configuracao.indVigenciaAutomatica) {
        this.acao.dtaInicio = this.moment().format('YYYY-MM-DD');
        this.acao.dtaFim = this.acao.dtaInicio;
      }
    },
    atualizarValorTetoAcao(valorTetoAcao) {
      if (this.isCalculoNDMaximoCampanha && this.desabilitaTetoAcao) {
        this.acao.tetoAcao = parseFloat(valorTetoAcao);
        this.componentKey += 1;
      }
    },
    atualizarValorTetoAcaoParaRateioContabil(valorTetoAcao) {
      this.valorTotalAcaoRateio = valorTetoAcao;
    },
    atualizaCountListaRateioTipoUso(countListaRateio) {
      this.countListaRateio = countListaRateio;
    },
    limparListaRateioTipoUso(indLimparListaRateio) {
      this.indLimparListaRateio = indLimparListaRateio;
    },
    verificacaoParaSolicitarAprovacaoRateio(podeSolicitarAprovacaoRateio) {
      this.podeSolicitarAprovacaoRateio = podeSolicitarAprovacaoRateio;
      this.$emit('PlanejamentoAcaoForm__isValorPendenteZerado', podeSolicitarAprovacaoRateio);
    },
    atualizarValorTetoAcaoRodape() {
      this.$refs.secaoProduto.recalcularValorTotalAcao();
    },
    getOrigemAcao() {
      return this.$refs.secaoOrigemAcao.getObjetoFormatado() || {};
    },
    apagarTodosProdutos() {
      this.$refs.secaoProduto.apagarTodosProdutos();
    },
    getListaProdutos() {
      return this.$refs.secaoProduto.getObjetoFormatado();
    },
    validarVinculoDeContrato(idFornecedor) {
      this.$refs.secaoConfiguracao.validarVinculoDeContrato(idFornecedor);
    },
    buscarContratoVinculado() {
      this.$refs.secaoConfiguracao.buscarContratos(this.acao.idContratoVinculado);
    },
    valorDataInicioAterior(dataInicioAnterior) {
      this.acao.dataInicioAnterior = dataInicioAnterior;
    },
    valorDataFimAterior(dataFimAnterior) {
      this.acao.dataFimAnterior = dataFimAnterior;
    },
    alterarConfiguracaoVinculo() {
      this.verificaSeAbreModalJustfExcedeRetroatividade(this.acao.dtaInicio);
      this.$refs.secaoConfiguracao.alterarConfiguracaoVinculo();
    },
    abrirModalJustfExcedeRetroatividade() {
      this.$refs.modalJustificativa.open();
    },
    abrirModalJustfExcedeRetroatividadeEdicao() {
      this.$refs.modalJustificativa.open();
      this.$refs.modalJustificativa.justificativa = this.justfExcedeRetroatividade;
      this.modalExcedeRetroatividadeEdicao = true;
    },
    verificaSeDataExcedeRetroatividade(data) {
      return moment(data).isBefore(this.dataMaximaCalcRetroativo);
    },
    verificaSeAbreModalJustfExcedeRetroatividade(dataInicio) {
      this.indExcedeRetroatividade = this.verificaSeDataExcedeRetroatividade(dataInicio);
      if (this.indExcedeRetroatividade && !this.justfExcedeRetroatividade) {
        this.abrirModalJustfExcedeRetroatividade();
      } else if (this.indExcedeRetroatividade && this.justfExcedeRetroatividade) {
        this.actionJustificativa(this.justfExcedeRetroatividade);
      }
    },
    dataInicioAlterada(dataInicioAlterada) {
      let indAlteracaoIdContratoVinculado = false;
      if (this.acao.dataInicioAnterior !== dataInicioAlterada) {
        indAlteracaoIdContratoVinculado = this.$refs.secaoOrigemAcao.verificaAlteracaoIdContratoVinculado();
      }
      if (!indAlteracaoIdContratoVinculado) {
        this.verificaSeAbreModalJustfExcedeRetroatividade(dataInicioAlterada);
      }
    },
    dataFimAlterada(dataFimAlterada) {
      if (this.acao.dataFimAnterior && this.acao.dataFimAnterior !== dataFimAlterada) {
        this.$refs.secaoOrigemAcao.verificaAlteracaoIdContratoVinculado();
      }
    },
    mostrarAcompanhamento(item) {
      return item.status !== 'EM_CADASTRO' && item.status !== 'CANCELADO';
    },
    abrirAcompanhamento(item) {
      this.dadosModalAcompanhamento = {
        idAcao: item.idAcao,
        modalidade: item.calculoBonificacao,
        emissao: item.emissao,
      };
      const { idAcao } = item;
      if (!item.temFluxo) {
        this.$toast(this.$t('message.acao_aprovada_automatica'));
        return;
      }

      this.workflowAcaoResource.statusPasso({ idAcao }, { idAcao })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          if (this.statusPassos.passos) {
            const idsPassos = this.statusPassos.passos.map((passo) => passo.id.toString());
            const params = {
              idPassos: idsPassos,
            };
            buscarPerfilPassoAprovador(params, this.$resource).then((result) => {
              this.idsPassosAprovadores = result.body;
              this.statusPassos.passos.forEach((passo) => {
                passo.perfil = this.idsPassosAprovadores.find((e) => e.id_passo_execucao === passo.id).nom_perfil;
              });
            });
            const passo = this.statusPassos.passos.find((e) => e.statusPasso === 'AGUARDANDO_APROVACAO');
            if (passo) {
              passo.isProximoPasso = true;
            }
          }
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    getCollorIcon(acao) {
      if (acao.emissao && acao.emissao.statusApuracao) {
        return acao.emissao.statusApuracao === 'APROVADO' ? '#1abc9c' : '#95a5a6';
      }
      return '#95a5a6';
    },
    abrirModalGerarNd(acao) {
      this.acaoModal = acao;
      this.acaoModal.cod_acao = acao.id;
      setTimeout(() => { this.abrirModalND = true; }, 2E2);
    },
    fecharModalND() {
      this.abrirModalND = false;
      setTimeout(() => { this.acaoModal = null; }, 2E2);
    },
    buscarChaveQtdeMesesCalculoRetroativo() {
      this.configSistemaResource.buscarChave({ chave: 'QTDE_MESES_CALCULO_RETROATIVO' })
        .then((resp) => {
          const qtdeMesesCalculoRetroativo = resp.body;
          this.dataMaximaCalcRetroativo = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'DD-MM-YYYY')
            .subtract(qtdeMesesCalculoRetroativo, 'months')
            .format('YYYY-MM-DD');

          this.indExcedeRetroatividade = this.verificaSeDataExcedeRetroatividade(this.acao.dtaInicio);
        });
    },
    actionJustificativa(justificativa) {
      if (!this.somenteLeitura) {
        this.justfExcedeRetroatividade = justificativa;
        if (this.modalExcedeRetroatividadeEdicao) this.modalExcedeRetroatividadeEdicao = false;
        if (this.modalExcedeRetroatividadeSalvar) {
          this.modalExcedeRetroatividadeSalvar = false;
          this.$emit('PlanejamentoAcaoForm__SalvarNaModalJustfExcedeRetroatividade', this.isSolicitandoAprovacao);
        }
      }
    },
    cancelarJustificativa() {
      if (!this.somenteLeitura) {
        if (this.modalExcedeRetroatividadeSalvar) this.modalExcedeRetroatividadeSalvar = false;
        if (this.isSolicitandoAprovacao) this.isSolicitandoAprovacao = false;
        else if (this.modalExcedeRetroatividadeEdicao) this.modalExcedeRetroatividadeEdicao = false;
        else if (!this.acao.dataInicioAnterior) {
          this.$refs.dtaInicio.limpaData();
        } else {
          this.indExcedeRetroatividade = this.verificaSeDataExcedeRetroatividade(this.acao.dataInicioAnterior);
          this.acao.dtaInicio = this.acao.dataInicioAnterior;
        }
      }
    },
  },
  mounted() {
    if (!this.somenteLeitura) {
      this.buscarDivisoesUsuario();
    }
    this.aplicaParametrizacoes();
    if (this.novaAcao) {
      this.preencherValoresAutomaticos();
    } else {
      this.montaObjetoAcao();
    }

    this.buscarChaveQtdeMesesCalculoRetroativo();
    window.scrollTo(0, 0);
  },
};
</script>
