<template>
  <div>
    <v-dialog v-model="exibeModalMetaIntervalo" :max-width="acao.possuiValorMaximoNd ? '800px' : '650px'" eager persistent>
      <v-card style="text-align: center">
        <v-card-title>
          <div class="card-title">
            {{ $tc('title.definicao_gatilho', 2) }}
          </div>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" >
              <v-form ref="formModalIntervalo" lazy-validation autocomplete="off">
                <v-data-table
                  id="tabela_intervalo_meta"
                  :headers="headersMetas"
                  :items="linhaProdutoSelecionada.metas"
                  disable-pagination
                  hide-default-footer
                  :key="tableMetasKey"
                  :items-per-page="-1"
                  :no-data-text="$t('label.tabela_sem_conteudo')"
                  >
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(itemMeta, index) in items" :key="index">
                        <td>
                          <input-money
                            v-if="calculoValor"
                            id="input_money_meta_de"
                            :label="`${$tc('label.meta_de', 1)} *`"
                            v-model="itemMeta.vlrMetaDe"
                            :value="itemMeta.vlrMetaDe"
                            :disabled="somenteLeitura"
                            class="v-text-field"
                            @change.native="valorMetaAlterado(itemMeta)"
                          />
                          <v-text-field
                            v-else-if="calculoPercentual"
                            id="text_field_percentual_meta_de"
                            :label="`${$tc('label.meta_de', 1)} *`"
                            v-model="itemMeta.vlrMetaDe"
                            :disabled="somenteLeitura"
                            type="Number"
                            min="0"
                            suffix="%"
                            :rules="[v => rules.valorMaximo(v, 100)]"
                            hide-spin-buttons
                            @change.native="valorMetaAlterado(itemMeta)"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            id="text_field_number_meta_de"
                            :label="`${$tc('label.meta_de', 1)} *`"
                            v-model="itemMeta.vlrMetaDe"
                            :disabled="somenteLeitura"
                            type="Number"
                            min="0"
                            hide-spin-buttons
                            @change.native="valorMetaAlterado(itemMeta)"
                          ></v-text-field>
                        </td>
                        <td>
                          <input-money
                            v-if="calculoValor"
                            id="input_money_meta_ate"
                            :label="$tc('label.meta_ate', 1)"
                            v-model="itemMeta.vlrMetaAte"
                            :value="itemMeta.vlrMetaAte"
                            :disabled="somenteLeitura"
                            class="v-text-field"
                            @change.native="valorMetaAlterado(itemMeta)"
                          />
                          <v-text-field
                            v-else-if="calculoPercentual"
                            id="text_field_percentual_meta_ate"
                            :label="$tc('label.meta_ate', 1)"
                            v-model="itemMeta.vlrMetaAte"
                            :disabled="somenteLeitura"
                            type="Number"
                            min="0"
                            suffix="%"
                            :rules="[v => rules.valorMaximo(v, 100)]"
                            hide-spin-buttons
                            @change.native="valorMetaAlterado(itemMeta)"
                          ></v-text-field>
                          <v-text-field
                            v-else
                            id="text_field_number_meta_ate"
                            :label="$tc('label.meta_ate', 1)"
                            v-model="itemMeta.vlrMetaAte"
                            :disabled="somenteLeitura"
                            type="Number"
                            min="0"
                            hide-spin-buttons
                            @change.native="valorMetaAlterado(itemMeta)"
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-if="verbaPercentual"
                            id="text_field_number_bonificacao"
                            v-model="itemMeta.vlrBonificacao"
                            suffix="%"
                            :rules="[v => rules.valorMinimo(v, 0.000000001), v => rules.valorMaximo(v, 100)]"
                            :disabled="somenteLeitura"
                            type="Number"
                            min="0"
                            hide-spin-buttons
                            @change.native="valorMetaAlterado(itemMeta)"
                          ></v-text-field>
                          <input-money
                            v-else
                            id="input_money_bonificacao"
                            v-model="itemMeta.vlrBonificacao"
                            :value="itemMeta.vlrBonificacao"
                            :disabled="somenteLeitura"
                            class="v-text-field"
                            @change.native="valorMetaAlterado(itemMeta)"
                          />
                        </td>
                        <td v-if="calculoQuantidade && !verbaPercentual">
                          <input-money
                            id="input_money_valor_maximo_faixa"
                            v-model="itemMeta.vlrMaximoFaixa"
                            :value="itemMeta.vlrMaximoFaixa"
                            disabled
                            class="v-text-field"
                          />
                        </td>
                        <td v-if="!somenteLeitura">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn icon class="mx-0" v-on="on" @click="openConfirmRemocaoIntervalo(itemMeta)">
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $tc('label.remover') }}</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
                <v-row class="my-2" v-if="!somenteLeitura">
                  <v-col cols="12" align-self="center" style="text-align: center">
                    <v-btn
                      id="btn_add_intervalo"
                      color="primary"
                      @click.native="incluirNovoIntervalo()">
                      {{ $t('label.adicionar_novo_intervalo') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-show="existeErroMeta">
                  <v-col cols="12" align-self="center" style="text-align: center">
                    <v-alert type="warning"
                      elevation="2"
                      class="pa-2"
                    >
                      {{ $tc('message.revise_metas_gatilho', 2) }}
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" align-self="center" style="text-align: center">
                    <v-btn
                      id="btn_cancelar"
                      color="secundary"
                      @click.native="cancelarEdicaoIntervalo()">
                      {{ somenteLeitura ? $t('label.voltar') : $t('label.cancelar') }}
                    </v-btn>
                    <v-btn
                      v-if="!somenteLeitura"
                      id="btn_salvar"
                      class="ml-3"
                      color="primary"
                      @click.native="salvarIntervalo()">
                      {{ $t('label.salvar') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <confirm
      ref="confirmRemocaoIntervalo"
      :message="$t('message.deseja_remover_meta')"
      :persistent="true"
      @agree="removerIntervalo">
    </confirm>
  </div>
</template>
<script>
import { copyObject } from '@/common/functions/helpers';
import { numberBetweenValues } from '@/common/functions/numeric';
import Confirm from '@/shared-components/dialog/Confirm';
import InputMoney from '@/shared-components/inputs/InputMoney';

export default {
  name: 'PlanejamentoAcaoVerbaVariavelModalMetaIntervalo',
  components: {
    Confirm,
    InputMoney,
  },
  props: {
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    acao: Object,
    configuracao: {
      type: Object,
      required: true,
    },
  },
  computed: {
    verbaPercentual() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'PERCENTUAL';
    },
    verbaFixo() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'FIXO';
    },
    valorPorPeca() {
      return this.acao.formaBonificacao
          && this.acao.formaBonificacao === 'VALOR_POR_PECA';
    },
    calculoValor() {
      return this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_VALOR'
          || this.acao.calculoApuracao === 'SELLOUT_VALOR');
    },
    calculoPercentual() {
      return this.acao.calculoApuracao
        && (this.acao.calculoApuracao === 'SELLIN_PERCENTUAL'
          || this.acao.calculoApuracao === 'SELLOUT_PERCENTUAL');
    },
    calculoQuantidade() {
      return this.acao.calculoApuracao
          && (this.acao.calculoApuracao === 'QUANTIDADE');
    },
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),

      exibeModalMetaIntervalo: false,
      existeErroMeta: false,
      metaSelecionada: null,
      tableMetasKey: 0,
      linhaProdutoSelecionada: {},

      metasEmEdicao: [],
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headersMetas: [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', width: '30%', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', width: '30%', sortable: false, align: 'center',
        },
        {
          text: this.$tc('label.valor', 1), value: 'bonificacao_pagamento', width: '30%', sortable: false, align: 'center',
        },
        {
          text: '', value: '', sortable: false, align: 'center',
        },
      ],
      rules: {
        required: (value) => (!!value || value === 0 || value === '0') || this.$t('message.campo_obrigatorio'),
        requiredValorPositivo: (value) => (!!value && value > 0) || this.$t('message.campo_obrigatorio'),
        metaAteMenorValor: (itemMeta) => (parseFloat(itemMeta.vlrMetaAte) > parseFloat(itemMeta.vlrMetaDe)
          || itemMeta.vlrMetaAte === 0) || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVolume: (itemMeta) => (!itemMeta.vlrMetaAte
          || parseFloat(itemMeta.vlrMetaAte) > parseFloat(itemMeta.vlrMetaDe)
          || itemMeta.vlrMetaAte === '0') || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVariavel: (itemMeta) => (!itemMeta.vlrMetaAte
          || parseFloat(itemMeta.vlrMetaAte) > parseFloat(itemMeta.vlrMetaDe)
          || itemMeta.vlrMetaAte === '0') || this.$t('errors.meta_ate_maior_meta_de'),
        valorMinimo: (value, min) => value >= min || this.$t('errors.valor.maior_zero_inteiro', { min }),
        valorMaximo: (value, max) => value <= max || this.$t('errors.valor.maximo', { max }),
      },
    };
  },
  watch: {
  },
  methods: {
    numberBetweenValues,
    open() {
      if (!this.linhaProdutoSelecionada.metas || !this.linhaProdutoSelecionada.metas.length) {
        this.incluirIntervaloZerado();
      }
      this.metasEmEdicao = copyObject(this.linhaProdutoSelecionada.metas);
      this.setarHeader();
      this.exibeModalMetaIntervalo = true;
      this.$refs.formModalIntervalo.resetValidation();
    },
    close() {
      this.exibeModalMetaIntervalo = false;
      this.resetModal();
    },
    setaLinhaProdutoSelecionada(linhaProdutoSelecionada) {
      this.linhaProdutoSelecionada = linhaProdutoSelecionada;
    },
    incluirNovoIntervalo() {
      if (!this.ultimoIntervaloPossuiMetaAte()) {
        this.existeErroMeta = true;
        return;
      }
      this.incluirIntervaloZerado();
      this.$refs.formModalIntervalo.resetValidation();
    },
    ultimoIntervaloPossuiMetaAte() {
      return this.linhaProdutoSelecionada.metas[this.linhaProdutoSelecionada.metas.length - 1].vlrMetaAte;
    },
    incluirIntervaloZerado() {
      let valorMeta = 0;
      if (this.linhaProdutoSelecionada.metas && this.linhaProdutoSelecionada.metas.length) {
        if (this.calculoValor) {
          valorMeta = this.linhaProdutoSelecionada.metas[this.linhaProdutoSelecionada.metas.length - 1].vlrMetaAte + parseFloat(0.01);
          this.linhaProdutoSelecionada.metas.push({
            vlrMetaDe: valorMeta,
            vlrMetaAte: 0.0,
            vlrBonificacao: 0.0,
          });
        } else {
          valorMeta = parseFloat(this.linhaProdutoSelecionada.metas[this.linhaProdutoSelecionada.metas.length - 1].vlrMetaAte) + parseFloat(1);
          this.linhaProdutoSelecionada.metas.push({
            vlrMetaDe: valorMeta,
            vlrMetaAte: '',
            vlrBonificacao: 0.0,
          });
        }
      }
      if (!this.linhaProdutoSelecionada.metas.length) {
        if (this.calculoValor) {
          this.linhaProdutoSelecionada.metas.push({
            vlrMetaDe: 0.0,
            vlrMetaAte: 0.0,
            vlrBonificacao: 0.0,
          });
        } else {
          this.linhaProdutoSelecionada.metas.push({
            vlrMetaDe: valorMeta,
            vlrMetaAte: '',
            vlrBonificacao: 0.0,
          });
        }
      }
      this.tableMetasKey += 1;
    },
    valorMetaAlterado(itemMeta) {
      if (this.calculoQuantidade) {
        if (itemMeta.vlrBonificacao) {
          if (this.verbaPercentual) {
            if (itemMeta.vlrMetaAte) {
              itemMeta.vlrMaximoFaixa = itemMeta.vlrMetaAte * (itemMeta.vlrBonificacao / 100);
            } else if (itemMeta.vlrMetaDe) {
              itemMeta.vlrMaximoFaixa = itemMeta.vlrMetaDe * (itemMeta.vlrBonificacao / 100);
            } else {
              itemMeta.vlrMaximoFaixa = 0;
            }
          }
          if (!this.verbaPercentual) {
            if (itemMeta.vlrMetaAte) {
              itemMeta.vlrMaximoFaixa = itemMeta.vlrMetaAte * itemMeta.vlrBonificacao;
            } else if (itemMeta.vlrMetaDe) {
              itemMeta.vlrMaximoFaixa = itemMeta.vlrMetaDe * itemMeta.vlrBonificacao;
            } else {
              itemMeta.vlrMaximoFaixa = 0;
            }
          }
        } else {
          itemMeta.vlrMaximoFaixa = 0;
        }
      }
    },
    openConfirmRemocaoIntervalo(itemMeta) {
      this.metaSelecionada = itemMeta;
      this.$refs.confirmRemocaoIntervalo.open();
    },
    removerIntervalo() {
      this.linhaProdutoSelecionada.metas
        .splice(this.linhaProdutoSelecionada.metas.indexOf(this.metaSelecionada), 1);
      this.tableMetasKey += 1;
    },
    cancelarEdicaoIntervalo() {
      this.linhaProdutoSelecionada.metas = copyObject(this.metasEmEdicao);
      this.close();
    },
    salvarIntervalo() {
      if (!this.$refs.formModalIntervalo.validate() || this.intervaloEmConflito()
        || this.valoresNegativos()) {
        this.existeErroMeta = true;
        return;
      }
      this.existeErroMeta = false;
      this.ordenarLista();
      if (!this.linhaProdutoSelecionada.valorMaximoNdAlterado) {
        this.setaValorMaximoNd();
      }
      this.$emit('ModalMetaIntervalo__RecalculaValorTotalRecebimento');
      setTimeout(() => {
        this.$emit('ModalMetaIntervalo__RecalculaValorTotalAcao');
      }, 2E2);
      this.close();
    },
    setaValorMaximoNd() {
      if (this.acao.possuiValorMaximoNd) {
        const { metas } = this.linhaProdutoSelecionada;
        if (this.calculoQuantidade && !this.verbaPercentual) {
          this.linhaProdutoSelecionada.valorMaximoNd = Math.max(...metas.map((m) => m.vlrMaximoFaixa));
          this.linhaProdutoSelecionada.recebimentoEstimado = this.linhaProdutoSelecionada.valorMaximoNd;
        } else if (!this.verbaPercentual) {
          this.linhaProdutoSelecionada.valorMaximoNd = Math.max(...metas.map((m) => m.vlrBonificacao));
          this.linhaProdutoSelecionada.recebimentoEstimado = this.linhaProdutoSelecionada.valorMaximoNd;
        }
      }
    },
    ordenarLista() {
      this.linhaProdutoSelecionada.metas.sort((a, b) => a.vlrMetaDe - b.vlrMetaDe);
    },
    intervaloEmConflito() {
      for (let i = 0; i < this.linhaProdutoSelecionada.metas.length; i += 1) {
        const intervaloA = this.linhaProdutoSelecionada.metas[i];
        if (intervaloA.vlrMetaAte && parseFloat(intervaloA.vlrMetaDe) >= parseFloat(intervaloA.vlrMetaAte)) {
          return true;
        }
        for (let j = 0; j < this.linhaProdutoSelecionada.metas.length; j += 1) {
          if (i !== j) {
            const intervaloB = this.linhaProdutoSelecionada.metas[j];
            if (this.existeConflito(intervaloA, intervaloB)) {
              return true;
            }
          }
        }
      }
      return false;
    },
    existeConflito(intervaloA, intervaloB) {
      if (!intervaloB.vlrMetaAte) {
        return this.existeConflitoEntreValorInfinito(intervaloA, intervaloB);
      }
      /**
       * caso intervalo sem meta ate (meta infinita) não seja o último da lista, ele vai vir como intervalo A aqui.
       * Portanto, deve ser validado como meta infinita, trocando ordem dos parametros
       * */
      if (!intervaloA.vlrMetaAte) {
        return this.existeConflitoEntreValorInfinito(intervaloB, intervaloA);
      }
      return this.existeConflitoEntreValores(intervaloA, intervaloB);
    },
    existeConflitoEntreValores(intervaloA, intervaloB) {
      return this.numberBetweenValues(parseFloat(intervaloB.vlrMetaDe), parseFloat(intervaloA.vlrMetaDe), parseFloat(intervaloA.vlrMetaAte))
          || this.numberBetweenValues(parseFloat(intervaloB.vlrMetaAte), parseFloat(intervaloA.vlrMetaDe), parseFloat(intervaloA.vlrMetaAte))
          || (parseFloat(intervaloA.vlrMetaAte) !== 0 && this.numberBetweenValues(parseFloat(intervaloA.vlrMetaAte),
            parseFloat(intervaloB.vlrMetaDe), parseFloat(intervaloB.vlrMetaAte)))
          || this.numberBetweenValues(parseFloat(intervaloA.vlrMetaDe), parseFloat(intervaloB.vlrMetaDe),
            parseFloat(intervaloB.vlrMetaAte));
    },
    existeConflitoEntreValorInfinito(intervaloA, intervaloB) {
      return this.numberBetweenValues(intervaloB.vlrMetaDe, intervaloA.vlrMetaDe, intervaloA.vlrMetaAte)
        || intervaloB.vlrMetaDe < intervaloA.vlrMetaDe;
    },
    valoresNegativos() {
      const linhaProduto = this.linhaProdutoSelecionada;
      for (let i = 0; i < linhaProduto.metas.length; i += 1) {
        const itemMeta = linhaProduto.metas[i];
        if (itemMeta.vlrMetaDe === '' || itemMeta.vlrMetaDe < 0 || itemMeta.vlrMetaAte < 0 || itemMeta.vlrBonificacao <= 0) {
          return true;
        }
      }
      return false;
    },
    resetModal() {
      this.existeErroMeta = false;
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    setarHeader() {
      if (this.calculoQuantidade && !this.verbaPercentual) {
        this.headersMetas = [
          {
            text: this.$t('label.meta_de'), value: 'meta_de', width: '20%', sortable: false, align: 'center',
          },
          {
            text: this.$t('label.meta_ate'), value: 'meta_ate', width: '20%', sortable: false, align: 'center',
          },
          {
            text: this.$tc('label.valor', 1), value: 'bonificacao_pagamento', width: '20%', sortable: false, align: 'center',
          },
          {
            text: this.$tc('label.valor_maximo_faixa', 1), value: 'vlr_maximo_faixa', width: '30%', sortable: false, align: 'center',
          },
          {
            text: '', value: '', sortable: false, align: 'center',
          },
        ];
        this.tableMetasKey += 1;
      }
    },
  },
  mounted() {
    this.tableMetasKey += 1;
  },
};
</script>
<style>
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo,
.ApuracaoAcaoDetalhesPagamentoModal__valorPendente-negativo input.v-money {
  color: #ff0000ba;
}
</style>
